import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorDOBActions = {
  apiFetching: createAction(
    "vendor/kyc/dob/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/kyc/dob/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/kyc/dob/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  setDOB: createAction("vendor/kyc/dob/setDOB", (dob: string) => ({
    payload: { dob },
  })),
  clearDOB: createAction("vendor/kyc/dob/clearDOB"),
  submitDOB: createAction("vendor/kyc/dob/submitDOB"),
};
export default vendorDOBActions;

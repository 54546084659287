import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { AsyncAddressStateType } from "@ob/layouts/VendorOnboarding/types/address";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initAddressState = createAsyncSlice({
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
});

const addressReducer = createReducer<AsyncAddressStateType>(
  initAddressState,
  (builder) => {
    builder
      .addCase(va.kyc.address.apiFetching, onApiFetching)
      .addCase(va.kyc.address.apiError, onApiError)
      .addCase(va.kyc.address.apiSuccess, onApiSuccess)
      .addCase(va.kyc.address.setAddressValue, onSetAddressValue)
      .addCase(va.kyc.address.clearAddress, onClearAddress);
  },
);
export default addressReducer;

export function onApiFetching(
  state: AsyncAddressStateType,
  action: ReturnType<typeof va.kyc.address.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AsyncAddressStateType,
  action: ReturnType<typeof va.kyc.address.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AsyncAddressStateType,
  action: ReturnType<typeof va.kyc.address.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetAddressValue(
  state: AsyncAddressStateType,
  action: ReturnType<typeof va.kyc.address.setAddressValue>,
) {
  if (action.payload.key in state.data) {
    state.data = {
      ...state.data,
      [action.payload.key]: action.payload.value,
    };
  }
}

export function onClearAddress(state: AsyncAddressStateType) {
  state.data.street1 = "";
  state.data.street2 = "";
  state.data.city = "";
  state.data.state = "";
  state.data.zip = "";
  state.fetching = false;
  state.error = {
    message: "",
    status: 0,
  };
}

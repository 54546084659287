import { Typography } from "@mui/material";
import StitchAlertIcon from "@ob/components/StitchAlertIcon";
import { FadeInStack } from "@ob/components/FadeComponents";

export default function ManualReview() {
  return (
    <FadeInStack
      alignItems="center"
      justifyContent="center"
      sx={{
        flex: 1,
        p: 3,
      }}
    >
      <StitchAlertIcon size="medium" />
      <Typography variant="h1" align="center">
        Your account needs further review.
      </Typography>
      <Typography variant="body1" sx={{ mt: 6 }} align="center">
        We’ll be in contact about next steps and may collect more information
        from you in order to approve your account for payments.
      </Typography>
    </FadeInStack>
  );
}

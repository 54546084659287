import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import {
  BusinessInfoStateType,
  BusinessStructureType,
} from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import { createSelector } from "@reduxjs/toolkit";

export const selectBusinessName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.business.info.businessName,
);

export const selectDoingBusinessAs = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.business.info.doingBusinessAs,
);

export const selectBusinessEin = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.business.info.ein,
);

export const selectBusinessStructure = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): BusinessStructureType => vendor.kyb.business.info.businessStructure,
);

export const selectBusinessInfo = createSelector(
  selectBusinessName,
  selectDoingBusinessAs,
  selectBusinessEin,
  selectBusinessStructure,
  (
    businessName,
    doingBusinessAs,
    ein,
    businessStructure,
  ): BusinessInfoStateType => ({
    businessName,
    doingBusinessAs,
    ein,
    businessStructure,
  }),
);

import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { DobStateType } from "@ob/layouts/VendorOnboarding/types/kyc/dob";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initDOBState = createAsyncSlice("");

const dobReducer = createReducer<DobStateType>(initDOBState, (builder) => {
  builder
    .addCase(va.kyc.dob.apiFetching, onApiFetching)
    .addCase(va.kyc.dob.apiError, onApiError)
    .addCase(va.kyc.dob.apiSuccess, onApiSuccess)
    .addCase(va.kyc.dob.setDOB, onSetDOB)
    .addCase(va.kyc.dob.clearDOB, onClearDOB);
});
export default dobReducer;

export function onApiFetching(
  state: DobStateType,
  action: ReturnType<typeof va.kyc.dob.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: DobStateType,
  action: ReturnType<typeof va.kyc.dob.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: DobStateType,
  action: ReturnType<typeof va.kyc.dob.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetDOB(
  state: DobStateType,
  action: ReturnType<typeof va.kyc.dob.setDOB>,
) {
  state.data = action.payload.dob;
}

export function onClearDOB(state: DobStateType) {
  state.data = "";
  state.fetching = false;
  state.error = {
    message: "",
    status: 0,
  };
}

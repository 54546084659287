import { useNavigate } from "react-router";
import { Button, Stack, useTheme } from "@mui/material";

type Props = {
  hasCards: boolean;
  hasBanks: boolean;
  openDeleteModal: () => void;
};
export default function ActionBtns(props: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const state = {
    topText: "",
    topAction: () => {},
    bottomText: "",
    bottomAction: () => {},
  };

  if (props.hasBanks && props.hasCards) {
    console.error("Both banks and cards are linked. This should not happen.");
    return null;
  }

  if (!props.hasBanks && props.hasCards) {
    state.topText = "Unlink Card";
    state.topAction = props.openDeleteModal;
    state.bottomText = "Update";
    state.bottomAction = () => navigate("/credit_card");
  }

  if (!props.hasCards && props.hasBanks) {
    state.topText = "Unlink Bank";
    state.topAction = props.openDeleteModal;
    state.bottomText = "Update";
    state.bottomAction = () => navigate("/bank_account");
  }

  return (
    <Stack gap={2}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={state.topAction}
        sx={{ color: theme.palette.primary.main }}
      >
        {state.topText}
      </Button>
      <Button variant="contained" onClick={() => state.bottomAction()}>
        {state.bottomText}
      </Button>
    </Stack>
  );
}

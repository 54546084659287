import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import Navbar from "@ob/layouts/VendorOnboarding/components/Navbar";
import { useAppSelector, useAppDispatch } from "@ob/redux/store";
import { selectNextRoute } from "./redux/selectors/routes";
import va from "./redux/actions";
import "./onboarding.css";

export default function VendorOnboarding() {
  const navigate = useNavigate();
  const nextRoute = useAppSelector(selectNextRoute);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (nextRoute) {
      navigate(nextRoute, { replace: true });
      dispatch(va.routes.clearNextRoute());
    }
  }, [nextRoute]);

  return (
    <Stack
      sx={{
        backgroundColor: "#FFF",
        flex: 1,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Navbar />
      <Outlet />
    </Stack>
  );
}

import { LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FadeInStack } from "@ob/components/FadeComponents";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function BusinessControllerRegister() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);

  const onContinueClick = () => {
    setIsLoading(true);
    navigate("/kyb/business_controller_info");
  };

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={77}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Register a business controller</Typography>
        <Typography variant="body1">
          In compliance with regulatory guidelines, we require you to provide
          information for at least one eligible representative.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Typography variant="body1" color="gray">
          Who is a business controller?
        </Typography>
        <Typography variant="body1" color="gray">
          Business controllers have significant management responsibilities.
          They could be senior officers, individuals who can appoint or remove
          senior officers, recognized decision-makers, and/or anyone who
          exercises other forms of substantial control over the company.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={onContinueClick}
          loading={loading}
          success={loading}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Stack } from "@mui/material";
import { MainRoutes } from "@ob/routes";
import StitchLoading from "@ob/components/StitchLoading";
import "./App.css";

function App() {
  const [router, setRouter] = useState<
    ReturnType<typeof createBrowserRouter> | undefined
  >();

  useEffect(() => {
    const setupRoutes = async () => {
      const routes = [await MainRoutes()];
      const rootRouter = createBrowserRouter(routes);
      setTimeout(() => {
        setRouter(rootRouter);
      }, 100);
    };

    setupRoutes();
  }, []);

  if (!router) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#FFF",
          flex: 1,
          width: "100%",
        }}
      >
        <StitchLoading size="large" />
      </Stack>
    );
  }
  return <RouterProvider router={router} />;
}

export default App;

import { ButtonBase, Stack, Typography, useTheme } from "@mui/material";
import { WarningOutlined } from "@ant-design/icons";
import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";

type Props = {
  error: ErrorPayload;
  onClick: () => void;
};
export default function GenericRetryError(props: Props) {
  const yellowColor = "#FAAD14";
  const theme = useTheme();

  if (!props.error.status) return null;
  if (props.error.status === MANUAL_ERROR_CODE) {
    return null;
  }

  return (
    <ButtonBase
      onClick={() => props.onClick()}
      sx={{
        minHeight: "40px",
        width: "100%",
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: "#FFFBE6",
        borderRadius: "4px",
        mb: 2,
        px: 2,
        py: 1,
        ":hover": {
          borderColor: yellowColor,
        },
        ":focus": {
          borerColor: theme.palette.primary.main,
        },
        ":active": {
          borerColor: theme.palette.primary.main,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="row" alignItems="center">
          <WarningOutlined
            style={{
              fontSize: "18px",
              color: yellowColor,
              paddingRight: "10px",
            }}
          />
          <Typography variant="body1" color="primary" sx={{ px: 1 }}>
            Sorry, something went wrong.
          </Typography>
        </Stack>
        <Typography variant="body1" color={yellowColor}>
          Retry
        </Typography>
      </Stack>
    </ButtonBase>
  );
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { Typography } from "@mui/material";

type Props = {
  error: ErrorPayload;
};
export default function LocalErrorMsg(props: Props) {
  if (props.error.status !== 444) return null;
  return (
    <Typography variant="subtitle1" color="error" align="center">
      {props.error.message ||
        "Sorry, we're unable to process your request. Please check your network connection and try again later."}
    </Typography>
  );
}

import { useEffect, useState } from "react";
import { Stack, Box, Typography, Button, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { FadeInStack } from "@ob/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import StitchLoading from "@ob/components/StitchLoading";
import { CloseCircleOutlined } from "@ant-design/icons";
import StitchSuccessIcon from "@ob/components/StitchSuccessIcon";
import {
  selectAuthFetching,
  selectAuthSuccess,
  selectAuthError,
  selectMerchantName,
} from "../../redux/selectors/auth";

export default function AddAcctSuccess() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const apiFetching = useAppSelector(selectAuthFetching);
  const apiError = useAppSelector(selectAuthError);
  const apiSuccess = useAppSelector(selectAuthSuccess);
  const merchantName = useAppSelector(selectMerchantName);

  const [uiFetching, setUiFetching] = useState(false);

  const { message: errMsg } = apiError;
  useEffect(() => {
    if (errMsg && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [uiFetching, errMsg]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jwt = params.get("auth");
    if (jwt) {
      setUiFetching(true);
      dispatch(va.auth.getUserData(jwt, { redirect: "" }));
    }
  }, []);

  if (!apiSuccess) {
    return (
      <FadeInStack
        style={{ width: "100vw", height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <StitchLoading />
      </FadeInStack>
    );
  }

  if (apiError.status) {
    return (
      <FadeInStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Stack gap={5} alignItems="center">
          <CloseCircleOutlined style={{ fontSize: "48px" }} />
          <Typography variant="h1" align="center">
            Oops.
          </Typography>
          <Typography variant="body1" align="center">
            {apiError.message || (
              <>
                Looks like something went wrong. <br /> Please try again.
              </>
            )}
          </Typography>
        </Stack>
      </FadeInStack>
    );
  }

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#FFF",
        p: 3,
        width: "100%",
        maxWidth: "748px",
      }}
    >
      <Box sx={{ width: "100%" }} />
      <Stack alignItems="center" justifyContent="center">
        <StitchSuccessIcon />
        <Typography variant="h1">{"You're"} all set.</Typography>
        <Typography variant="body1" sx={{ mt: 6 }} align="center">
          Your account has been linked to {merchantName}.
        </Typography>
      </Stack>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        sx={{
          borderColor: theme.palette.secondary.light,
          mb: 3,
        }}
        onClick={() => navigate("/linked_accounts")}
      >
        View linked account
      </Button>
    </FadeInStack>
  );
}

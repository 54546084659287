import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorPhoneActions = {
  apiFetching: createAction(
    "vendor/phone/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/phone/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/phone/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  setPhoneValue: createAction(
    "vendor/phone/setPhoneValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  setOtpValid: createAction(
    "vendor/phone/setOtpValid",
    (otpValid: boolean) => ({
      payload: { otpValid },
    }),
  ),
  clearPhoneNumber: createAction("vendor/phone/clearPhoneNumber"),
  clearPhoneCode: createAction("vendor/phone/clearPhoneCode"),
  retryPhone: createAction("vendor/phone/retryPhone"),
  resendPhoneCode: createAction("vendor/phone/resendPhoneCode"),
  submitPhone: createAction("vendor/phone/submitPhone"),
  submitPhoneCode: createAction("vendor/phone/submitPhoneCode"),
};
export default vendorPhoneActions;

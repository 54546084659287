import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectSSN,
  selectSSNError,
  selectSSNFetching,
  selectSSNSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/ssn";
import formatSSN from "@ob/utils/formatSSN";
import { validateSSN } from "@ob/utils/validation";

export default function SSN() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    ssn: "",
  });
  const hasErrors = Boolean(uiErrors.ssn);

  const ssn = useAppSelector(selectSSN);
  const apiFetching = useAppSelector(selectSSNFetching);
  const apiError = useAppSelector(selectSSNError);
  const apiSuccess = useAppSelector(selectSSNSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const validSSN = validateSSN(ssn, handleValidationResult("ssn"));
    if (!validSSN) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyc.ssn.submitSSN());
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "-") {
        nextValue = value.slice(0, -2);
      }
      validateSSN(nextValue, handleValidationResult(name));
      dispatch(va.kyc.ssn.setSSN(nextValue));
    }
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.ssn,
      value.length === 11,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    validateSSN(value, handleValidationResult(name));
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyc.ssn.setSSN(nextValue));
    } else if (value.length <= 11) {
      dispatch(va.kyc.ssn.setSSN(value));
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateSSN(e.target.value, handleValidationResult(e.target.name));
    setShowErrors(true);
  };

  useEffect(() => {
    if (apiSuccess) {
      navigate("/kyc/address");
      dispatch(va.kyc.ssn.apiFetching(false));
    }
  }, [apiSuccess]);

  const clearAsyncState = useCallback(() => {
    dispatch(va.kyc.ssn.apiError({ message: "", status: 0 }));
    dispatch(va.kyc.ssn.apiSuccess(false));
    dispatch(va.kyc.ssn.apiFetching(false));
  }, []);

  useEffect(() => {
    clearAsyncState();
    return () => clearAsyncState();
  }, []);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">SSN</Typography>
        <Typography variant="body1">
          Enter your full social security number to verify your identity.
        </Typography>
      </Stack>
      <Stack data-testid="phone-input" gap={2}>
        <Stack sx={{ minHeight: "75px" }}>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="XXX-XX-XXXX"
            name="ssn"
            value={formatSSN(ssn)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={handleOnBlur}
            className="ssn-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                hasErrors && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && hasErrors ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.ssn}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorEmailActions = {
  apiFetching: createAction(
    "vendor/kyc/email/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "vendor/kyc/email/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "vendor/kyc/email/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setEmail: createAction("vendor/kyc/email/setEmail", (email: string) => ({
    payload: { email },
  })),
  clearEmail: createAction("vendor/kyc/email/clearEmail"),
  submitEmail: createAction("vendor/kyc/email/submitEmail"),
};
export default vendorEmailActions;

import { Box } from "@mui/material";
import alertSvg from "@ob/assets/svgs/stitch-alert.svg";

type Props = {
  style?: object;
  size?: "small" | "medium" | "large";
};
export default function StitchAlertIcon({
  style = {},
  size = "medium",
}: Props) {
  const small = (
    <Box sx={{ ...style }}>
      <img src={alertSvg} alt="Alert" style={{ width: "80px" }} />
    </Box>
  );
  if (size === "small") {
    return small;
  }

  const medium = (
    <Box sx={{ ...style }}>
      <img src={alertSvg} alt="Alert" style={{ width: "215px" }} />
    </Box>
  );
  if (size === "medium") {
    return medium;
  }

  return (
    <Box sx={{ ...style }}>
      <img src={alertSvg} alt="Alert" />
    </Box>
  );
}

import { RightOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import {
  useTheme,
  ButtonBase,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { FadeInStack } from "@ob/components/FadeComponents";
import { useNavigate } from "react-router";

export default function GetStarted() {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleIndividualClicked = () => {
    navigate("/kyc/name");
  };

  const handleBusinessClicked = () => {
    navigate("/kyb/business_info");
  };

  return (
    <FadeInStack
      alignItems="flex-start"
      sx={{
        maxWidth: "748px",
        p: 3,
        m: "auto",
        minHeight: "100vh",
        width: "100%",
      }}
      gap={5}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Get started</Typography>
        <Typography variant="body1">
          Choose how you would like to accept and make payments.
        </Typography>
      </Stack>
      <Stack
        gap={1}
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{
          width: "100%",
          pt: 2,
          minHeight: 5,
          mt: 4,
        }}
      >
        <ButtonBase
          sx={{
            alignItems: "flex-start",
            width: "100%",
            transition: "background-color 0.5s ease",
            ":hover": {
              backgroundColor: "#F0F0F0",
            },
            p: "15px",
          }}
          onClick={() => handleIndividualClicked()}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <UserOutlined style={{ fontSize: "24px" }} />
              <Stack sx={{ ml: 2, width: "100%" }} justifyContent="flex-start">
                <Typography variant="body1" align="left">
                  Individual
                </Typography>
                <Typography variant="body1" align="left" color="secondary">
                  SSN required
                </Typography>
              </Stack>
            </Stack>
            <RightOutlined style={{ fontSize: "12px" }} />
          </Stack>
        </ButtonBase>
        <Divider
          sx={{
            my: 1,
            width: "100%",
            borderBottomColor: theme.palette.secondary.light,
          }}
        />
        <ButtonBase
          sx={{
            alignItems: "flex-start",
            width: "100%",
            transition: "background-color 0.3s ease",
            ":hover": {
              backgroundColor: "#F0F0F0",
            },
            p: "15px",
          }}
          onClick={() => handleBusinessClicked()}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <ShopOutlined style={{ fontSize: "24px" }} />
              <Stack sx={{ ml: 2, width: "100%" }} justifyContent="flex-start">
                <Typography variant="body1" align="left">
                  Business
                </Typography>
                <Typography variant="body1" align="left" color="secondary">
                  EIN required
                </Typography>
              </Stack>
            </Stack>
            <RightOutlined style={{ fontSize: "12px" }} />
          </Stack>
        </ButtonBase>
      </Stack>
    </FadeInStack>
  );
}

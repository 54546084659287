import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import transformBeToFeAddr from "@ob/api/utils/transformBeToFeAddr";
import {
  UpdateInviteReqType,
  UpdateInviteResType,
  SubmitInviteReqType,
  SubmitInviteResType,
  LoadInviteResType,
  GetInviteStatusResType,
  KyvUserTypes,
} from "@ob/api/types";
import { DirtyAddressDbType } from "@ob/redux/types/dbTypes";

export const fetchLoadInvite = async (
  jwt: string,
): Promise<LoadInviteResType> => {
  const url = `${endpoints.stitchBackend}/onboarding/invite?auth=${jwt}`;
  const response = await safeFetch(url, { method: "GET" });
  if ("error" in response) {
    return response;
  }
  let result = { ...response.data };
  if (response.data.address) {
    result = {
      ...response.data,
      address: transformBeToFeAddr(response.data.address),
    };
  }
  if (response.data.business) {
    if (response.data.business.address) {
      result.business = {
        ...response.data.business,
        address: transformBeToFeAddr(response.data.business.address),
      };
    }
    if (response.data.business.controller) {
      result.business.controller = {
        ...response.data.business.controller,
        address: transformBeToFeAddr(response.data.business.controller.address),
      };
    }
    if (response.data.business.admin) {
      result.business.admin = {
        ...response.data.business.admin,
        address: transformBeToFeAddr(response.data.business.admin.address),
      };
    }
    if (response.data.business.beneficialOwners) {
      result.business.beneficialOwners =
        response.data.business.beneficialOwners.map(
          (owner: { address: DirtyAddressDbType }) => ({
            ...owner,
            address: transformBeToFeAddr(owner.address),
          }),
        );
    }
  }
  return { data: result };
};

export const fetchUpdateInvite = async (
  jwt: string,
  updatedData: UpdateInviteReqType,
  userType: KyvUserTypes,
): Promise<UpdateInviteResType> => {
  const url = `${endpoints.stitchBackend}/onboarding/invite?auth=${jwt}&user_type=${userType}`;
  const response = await safeFetch(url, {
    method: "PUT",
    body: JSON.stringify(updatedData),
  });
  if ("error" in response) {
    return response;
  }
  let result = { ...response.data };
  if (response.data.address) {
    result = {
      ...response.data,
      address: transformBeToFeAddr(response.data.address),
    };
  }
  if (response.data.business) {
    if (response.data.business.address) {
      result.business = {
        ...response.data.business,
        address: transformBeToFeAddr(response.data.business.address),
      };
    }
    if (response.data.business.controller) {
      result.business.controller = {
        ...response.data.business.controller,
        address: transformBeToFeAddr(response.data.business.controller.address),
      };
    }
    if (response.data.business.admin) {
      result.business.admin = {
        ...response.data.business.admin,
        address: transformBeToFeAddr(response.data.business.admin.address),
      };
    }
    if (response.data.business.beneficialOwners) {
      result.business.beneficialOwners =
        response.data.business.beneficialOwners.map(
          (owner: { address: DirtyAddressDbType }) => ({
            ...owner,
            address: transformBeToFeAddr(owner.address),
          }),
        );
    }
  }
  return { data: result };
};

export const fetchSubmitInvite = async (
  jwt: string,
  reqData: SubmitInviteReqType,
  userType: KyvUserTypes,
): Promise<SubmitInviteResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/invite?auth=${jwt}&user_type=${userType}`,
    { method: "POST", body: JSON.stringify(reqData) },
  );
  return response;
};

export const fetchGetInviteStatus = async (
  jwt: string,
): Promise<GetInviteStatusResType> => {
  const url = `${endpoints.stitchBackend}/onboarding/invite/status?auth=${jwt}`;
  const response = await safeFetch(url, { method: "GET" });
  if ("error" in response) {
    return response;
  }
  return response;
};

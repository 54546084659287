import { useEffect, useState } from "react";
import { Stack, Typography, Button } from "@mui/material";
import {
  selectAllBanks,
  selectAllCards,
  selectPayDestError,
  selectPayDestFetching,
  selectPayDestSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/payDest";
import { FadeInStack } from "@ob/components/FadeComponents";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import StitchLoading from "@ob/components/StitchLoading";
import NoAccountMenu from "./components/NoAccountsMenu";
import PopulatedAccounts from "./components/PopulatedAccts";

export default function LinkedAccounts() {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectPayDestFetching);
  const apiError = useAppSelector(selectPayDestError);
  const apiSuccess = useAppSelector(selectPayDestSuccess);
  const cards = useAppSelector(selectAllCards);
  const banks = useAppSelector(selectAllBanks);
  const [uiFetching, setUiFetching] = useState(false);

  const errMsg = apiError.message;
  useEffect(() => {
    if (errMsg && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
    if (apiSuccess) {
      dispatch(va.payDest.apiSuccess(false));
      setTimeout(() => dispatch(va.payDest.apiSuccess(false)), 5000);
    }
  }, [errMsg, apiFetching, uiFetching]);

  const handleGetPayDests = () => {
    setUiFetching(true);
    dispatch(va.payDest.getPaymentDests());
  };

  useEffect(() => {
    handleGetPayDests();
    return () => {
      dispatch(va.payDest.apiSuccess(false));
    };
  }, []);

  if (uiFetching) {
    return <StitchLoading />;
  }

  if (apiError.status) {
    return (
      <FadeInStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          maxWidth: "748px",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Stack gap={5} alignItems="center">
          <CloseCircleOutlined style={{ fontSize: "48px" }} />
          <Typography variant="h1" align="center">
            Oops.
          </Typography>
          <Typography variant="body1" align="center">
            {apiError.message || (
              <>
                Looks like something went wrong. <br /> Please try again.
              </>
            )}
          </Typography>
          <Stack gap={1} style={{ width: "100%" }}>
            <Button
              variant="contained"
              onClick={() => handleGetPayDests()}
              sx={{ display: apiError.status === 400 ? "none" : "block" }}
            >
              Retry
            </Button>
          </Stack>
        </Stack>
      </FadeInStack>
    );
  }

  if (!banks.length && !cards.length) {
    return <NoAccountMenu />;
  }
  return <PopulatedAccounts />;
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorKycFullNameActions = {
  apiFetching: createAction(
    "vendor/kyc/fullName/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "vendor/kyc/fullName/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "vendor/kyc/fullName/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setNameValue: createAction(
    "vendor/kyc/fullName/setNameValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  clearName: createAction("vendor/kyc/fullName/clearName"),
  submitName: createAction("vendor/kyc/fullName/submitName"),
};
export default vendorKycFullNameActions;

import { useEffect, useState } from "react";
import GenericRetryError from "@ob/components/GenericRetryError";
import StitchLoading from "@ob/components/StitchLoading";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import { FadeInStack } from "@ob/components/FadeComponents";
import { Divider, Stack, Typography, useTheme, Box } from "@mui/material";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import CardDetails from "../CardDetails";
import BankDetails from "../BankDetails";
import {
  selectAllBanks,
  selectAllCards,
  selectPayDestError,
  selectPayDestFetching,
  selectPayDestSuccess,
} from "../../../../redux/selectors/payDest";
import ConfirmDeleteCardModal from "../ConfirmDeleteModal";
import { selectMerchantName } from "../../../../redux/selectors/auth";
import ActionBtns from "../ActionBtns";

export default function PopulatedAccounts() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectPayDestFetching);
  const apiError = useAppSelector(selectPayDestError);
  const apiSuccess = useAppSelector(selectPayDestSuccess);
  const merchantName = useAppSelector(selectMerchantName);
  const cards = useAppSelector(selectAllCards);
  const banks = useAppSelector(selectAllBanks);
  const [deleteFetching, setDeleteFetching] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const errMsg = apiError.message;
  useEffect(() => {
    if (errMsg && deleteFetching) {
      setDeleteFetching(false);
    } else if (!apiFetching && deleteFetching) {
      setDeleteFetching(false);
    }
  }, [errMsg, apiFetching, deleteFetching]);

  useEffect(
    () =>
      function unmount() {
        dispatch(va.payDest.apiSuccess(false));
        dispatch(va.payDest.apiFetching(false));
        dispatch(va.payDest.apiError({ status: 0, message: "" }));
      },
    [],
  );

  const handleRetry = () => {
    if (!apiFetching) {
      dispatch(va.payDest.getPaymentDests());
    }
  };

  const handleCloseDeleteModal = () => {
    dispatch(va.payDest.apiSuccess(false));
    setOpenModal(false);
  };

  const handleDelete = (type: string) => {
    if (type === "card") {
      const id = cards[0]?.id;
      if (!apiFetching && id) {
        setDeleteFetching(true);
        dispatch(va.payDest.deleteCard(id));
      }
    } else if (type === "bank") {
      const id = banks[0]?.id;
      if (!apiFetching && id) {
        setDeleteFetching(true);
        dispatch(va.payDest.deleteBankAcct(id));
      }
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenModal(true);
    dispatch(va.payDest.apiSuccess(false));
  };

  const renderCardsOrFetching = () => {
    if (apiFetching && !deleteFetching) {
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ py: 7 }}>
          <StitchLoading size="medium" />
        </Stack>
      );
    }
    if (cards.length) {
      return cards.map((card) => (
        <Box key={card.id} sx={{ width: "100%" }}>
          <CardDetails key={card.id} card={card} />
          <Divider
            sx={{
              my: 1,
              borderBottomColor: theme.palette.secondary.light,
            }}
          />
        </Box>
      ));
    }
    if (banks.length) {
      return banks.map((bank) => (
        <Box key={bank.id} sx={{ width: "100%" }}>
          <BankDetails key={bank.id} bank={bank} />
          <Divider
            sx={{
              my: 1,
              borderBottomColor: theme.palette.secondary.light,
            }}
          />
        </Box>
      ));
    }
    return null;
  };

  return (
    <FadeInStack
      gap={1}
      justifyContent="space-between"
      sx={{
        p: 3,
        flexGrow: 1,
        width: "100%",
        maxWidth: "748px",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "100%" }}>
          <Typography variant="h2">Linked accounts</Typography>
          <Typography variant="body1">
            You can link one account to {merchantName}.
          </Typography>
        </Stack>
        {renderCardsOrFetching()}
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleRetry} />
        <ActionBtns
          hasCards={!!cards.length}
          hasBanks={!!banks.length}
          openDeleteModal={handleOpenDeleteModal}
        />
      </Stack>
      <ConfirmDeleteCardModal
        show={openModal}
        fetching={deleteFetching}
        success={apiSuccess}
        hasCards={!!cards.length}
        hasBanks={!!banks.length}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
      />
    </FadeInStack>
  );
}

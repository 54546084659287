import { useEffect, useState } from "react";
import { Stack, Typography, Button } from "@mui/material";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { useNavigate } from "react-router";

type Props = {
  apiError: {
    message: string;
    status: number;
    field?: string;
  };
  onRetry: () => void;
};
export default function CreateUserApiError(props: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorStep, setErrorStep] = useState("");
  const { apiError, onRetry } = props;

  const handleNavigateBack = () => {
    navigate(errorStep || "/kyc/name");
  };

  const handleKYCApiErrorFromField = (field: string) => {
    switch (field) {
      case "phone":
        setErrorStep("/phone");
        dispatch(va.phone.clearPhoneNumber());
        dispatch(va.phone.clearPhoneCode());
        dispatch(va.phone.apiSuccess(false));
        break;
      case "email":
        setErrorStep("/kyc/email");
        dispatch(va.kyc.email.clearEmail());
        dispatch(va.kyc.email.apiSuccess(false));
        break;
      case "name":
        setErrorStep("/kyc/name");
        dispatch(va.kyc.fullName.clearName());
        dispatch(va.kyc.fullName.apiSuccess(false));
        break;
      case "address":
        setErrorStep("/kyc/address");
        dispatch(va.kyc.address.clearAddress());
        dispatch(va.kyc.address.apiSuccess(false));
        break;
      case "dob":
        setErrorStep("/kyc/birthday");
        dispatch(va.kyc.dob.clearDOB());
        dispatch(va.kyc.dob.apiSuccess(false));
        break;
      case "taxId":
        setErrorStep("/kyc/ssn");
        dispatch(va.kyc.ssn.clearSSN());
        dispatch(va.kyc.ssn.apiSuccess(false));
        break;
      default:
        console.warn(`Could not handle KYC API error field: ${field}`);
        break;
    }
  };

  const handleKYBApiErrorFromField = (field: string) => {
    const [primary, secondary] = field.split(".");
    const ownerRx = /^owner_(\d)\.+/;
    const ownerParse = ownerRx.exec(field);

    switch (primary) {
      case "header":
        if (secondary === "address") {
          setErrorStep("/kyb/business_address");
        } else {
          setErrorStep("/kyb/business_info");
        }
        break;
      case "admin":
        if (secondary === "address") {
          setErrorStep("/kyb/admin_address");
        } else {
          setErrorStep("/kyb/admin_info");
        }
        break;
      case "controller":
        if (secondary === "address") {
          setErrorStep("/kyb/business_controller_address");
        } else {
          setErrorStep("/kyb/business_controller_info");
        }
        break;
      default:
        if (ownerParse?.length === 2) {
          const ownerIndex = ownerParse[1];
          if (
            secondary === "line1" ||
            secondary === "line2" ||
            secondary === "city" ||
            secondary === "state" ||
            secondary === "postal_code"
          ) {
            setErrorStep(`/kyb/owners_address/${ownerIndex}`);
          } else {
            setErrorStep(`/kyb/owners_info/${ownerIndex}`);
          }
        } else {
          console.warn(`Could not handle KYB API error field: ${field}`);
        }
        break;
    }
  };

  const { message, status, field } = apiError;
  useEffect(() => {
    if (!field) {
      return;
    }
    const isKybSubmit =
      field.startsWith("owner_") ||
      field.startsWith("header.") ||
      field.startsWith("admin.") ||
      field.startsWith("controller.");
    if (isKybSubmit) {
      handleKYBApiErrorFromField(field);
    } else {
      handleKYCApiErrorFromField(field);
    }
  }, [message, status, field]);

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
        height: "100%",
        p: 3,
      }}
    >
      <Stack gap={5} alignItems="center">
        <CloseCircleOutlined style={{ fontSize: "48px" }} />
        <Typography variant="h1" align="center">
          Oops.
        </Typography>
        <Typography variant="body1" align="center">
          {apiError.message || (
            <>
              Looks like something went wrong. <br /> Please try again.
            </>
          )}
        </Typography>
        <Stack gap={1} style={{ width: "100%" }}>
          <Button
            name="retry"
            variant="contained"
            onClick={() => onRetry()}
            sx={{ display: apiError.status === 400 ? "none" : "block" }}
          >
            Retry
          </Button>
          <Button name="back" variant="outlined" onClick={handleNavigateBack}>
            Back
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { SingleBeneficialOwnerStateType } from "@ob/layouts/VendorOnboarding/types/kyb/owners";
import { createSelector } from "@reduxjs/toolkit";

export const newBeneficialOwner: SingleBeneficialOwnerStateType = {
  id: "",
  info: {
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    ssn: "",
    title: "",
  },
  address: {
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  },
};

export const selectBeneficialOwners = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): SingleBeneficialOwnerStateType[] => vendor.kyb.owners.allOwners,
);

export const selectActiveIndex = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): number => vendor.kyb.owners.activeIndex,
);

export const selectBeneficialOwnerByParam = createSelector(
  (_: { vendor: VendorStateType }, paramIndex: number) => paramIndex,
  selectBeneficialOwners,
  (paramIndex, allOwners): SingleBeneficialOwnerStateType => {
    if (allOwners.length === 0) {
      return { ...newBeneficialOwner };
    }
    const result = allOwners[paramIndex];
    return result;
  },
);

export default selectBeneficialOwners;

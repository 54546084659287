import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

export const selectFirstName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.fullName.data.firstName,
);

export const selectLastName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.fullName.data.lastName,
);

export const selectFullNameFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.fullName.fetching,
);

export const selectFullNameError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.fullName.error,
);

export const selectFullNameSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.fullName.success,
);

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import GenericRetryError from "@ob/components/GenericRetryError";
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectPhoneError,
  selectPhoneFetching,
  selectPhoneNumber,
  selectPhoneSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/phone";
import validatePhone from "./utils/validation";
import formatPhoneNumber from "./utils/formatPhoneNumber";
import InfoMsg from "./components/InfoMsg";

export default function PhoneNumber() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    phoneNumber: "",
  });
  const hasErrors = Boolean(uiErrors.phoneNumber);

  const phoneNumber = useAppSelector(selectPhoneNumber);
  const apiFetching = useAppSelector(selectPhoneFetching);
  const apiError = useAppSelector(selectPhoneError);
  const apiSuccess: boolean = useAppSelector(selectPhoneSuccess);
  const usPhoneRef = useRef<HTMLDivElement>(null);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const formattedPhoneNum = formatPhoneNumber(phoneNumber);
    const phoneIsValid = validatePhone(
      formattedPhoneNum,
      handleValidationResult("phoneNumber"),
    );

    if (formattedPhoneNum !== phoneNumber) {
      dispatch(va.phone.setPhoneValue("phoneNumber", formattedPhoneNum));
    }
    if (!phoneIsValid) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.phone.submitPhone());
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (
      [
        "inputType" in e.nativeEvent &&
          e.nativeEvent.inputType === "deleteContentBackward",
      ].some(Boolean)
    ) {
      return;
    }

    validatePhone(value, handleValidationResult(name));
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.phone.setPhoneValue(name, nextValue));
    } else if (value.length <= 12) {
      dispatch(va.phone.setPhoneValue(name, value));
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (usPhoneRef.current) {
      usPhoneRef.current.style.outline = "none";
    }
    validatePhone(e.target.value, handleValidationResult(e.target.name));
    setShowErrors(true);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "-") {
        nextValue = value.slice(0, -2);
      }
      validatePhone(nextValue, handleValidationResult(name));
      dispatch(va.phone.setPhoneValue(name, nextValue));
    } else if (key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (apiSuccess) {
      navigate("/phone_verification");
      dispatch(va.phone.apiSuccess(false));
    }
  }, [apiSuccess]);

  useEffect(
    () =>
      function unmount() {
        dispatch(va.phone.apiSuccess(false));
        dispatch(va.phone.apiFetching(false));
        dispatch(
          va.phone.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Phone number</Typography>
        <Typography variant="body1">
          Enter your phone number to complete SMS verification.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Stack gap={1} sx={{ minHeight: "75px" }}>
          <Stack direction="row">
            <Stack
              ref={usPhoneRef}
              alignItems="center"
              justifyContent="center"
              className="usPhone-input"
              sx={{
                width: "65px",
                height: "40px",
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.lighter,
                border: `1px solid ${
                  hasErrors && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                borderRadius: "4px 0 0 4px",
                borderRight: "none",
                fontSize: "16px",
              }}
            >
              US +1
            </Stack>
            <input
              data-testid="phone-input"
              disabled={apiFetching}
              type="text"
              pattern="[0-9]*"
              inputMode="text"
              placeholder="123-123-1234"
              name="phoneNumber"
              value={formatPhoneNumber(phoneNumber)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onFocus={() => {
                if (usPhoneRef.current) {
                  usPhoneRef.current.style.outline = "1px solid black";
                }
              }}
              onBlur={handleOnBlur}
              className="phoneNumber-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  hasErrors && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "0 4px 4px 0",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
          </Stack>
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              display: showErrors && uiErrors.phoneNumber ? "block" : "none",
              lineHeight: "16px",
              pt: "5px",
              pl: "10px",
            }}
          >
            {uiErrors.phoneNumber}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <InfoMsg error={apiError} />
        <LocalErrorMsg error={apiError} />
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

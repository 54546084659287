import { jwtDecode } from "jwt-decode";

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { UserDBType } from "@ob/redux/types/dbTypes";

export const selectJWT = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.auth.data.jwt,
);

export const selectMerchantName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => {
    if (vendor.auth.data.jwt === "") {
      return "";
    }
    const decodedToken: { name: string } = jwtDecode(vendor.auth.data.jwt);
    const name = decodedToken?.name || "";
    if (name) {
      const merchantName =
        name.split("")[0].toUpperCase() + name.split("").slice(1).join("");
      return merchantName;
    }
    return "";
  },
);

export const selectJwtIsSelfServe = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => {
    if (vendor.auth.data.jwt === "") {
      return false;
    }
    const decodedToken: { stand_alone: boolean } = jwtDecode(
      vendor.auth.data.jwt,
    );
    return decodedToken?.stand_alone;
  },
);

export const selectAuthFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.auth.fetching,
);

export const selectAuthError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.auth.error,
);

export const selectAuthSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.auth.success,
);

export const selectLoggedIn = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.auth.data.loggedIn,
);

export const selectUserData = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): null | UserDBType => vendor.auth.data.userData,
);

import ErrorBoundary from "@ob/components/ErrorBoundary";
import Page404 from ".";

export default [
  {
    path: "404",
    element: <Page404 />,
    errorElement: <ErrorBoundary fallback />,
  },
  {
    path: "*",
    element: <Page404 />,
    errorElement: <ErrorBoundary fallback />,
  },
];

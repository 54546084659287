import { createReducer } from "@reduxjs/toolkit";
import { AddressStateType } from "@ob/layouts/VendorOnboarding/types/address";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initAddressState = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
};

const addressReducer = createReducer<AddressStateType>(
  initAddressState,
  (builder) => {
    builder
      .addCase(va.kyb.controller.address.setAddressValue, onSetAddressValue)
      .addCase(va.kyb.controller.address.clearAddress, onClearAddress);
  },
);
export default addressReducer;

export function onSetAddressValue(
  state: AddressStateType,
  action: ReturnType<typeof va.kyb.controller.address.setAddressValue>,
) {
  if (action.payload.key in state) {
    switch (action.payload.key) {
      case "street1":
        state.street1 = action.payload.value;
        break;
      case "street2":
        state.street2 = action.payload.value;
        break;
      case "city":
        state.city = action.payload.value;
        break;
      case "state":
        state.state = action.payload.value;
        break;
      case "zip":
        state.zip = action.payload.value;
        break;
      default:
        break;
    }
  }
}

export function onClearAddress(state: AddressStateType) {
  state.street1 = "";
  state.street2 = "";
  state.city = "";
  state.state = "";
  state.zip = "";
}

import { combineReducers } from "@reduxjs/toolkit";

import { VendorStateType } from "../../types";
import authReducer from "./auth";
import phoneReducer from "./phone";
import submitInviteReducer from "./submit";
import fullNameReducer from "./kyc/fullName";
import dobReducer from "./kyc/dob";
import ssnReducer from "./kyc/ssn";
import addressReducer from "./kyc/address";
import emailReducer from "./kyc/email";
import routeReducer from "./routes";
import payDestReducer from "./payDest";
import configReducer from "./config";
import businessSyncReducer from "./kyb/sync";
import businessInfoReducer from "./kyb/business/info";
import businessAddressReducer from "./kyb/business/address";
import adminInfoReducer from "./kyb/admin/info";
import adminAddressReducer from "./kyb/admin/address";
import businessControllerInfoReducer from "./kyb/controller/info";
import businessControllerAddressReducer from "./kyb/controller/address";
import beneficialOwnersReducer from "./kyb/owners";

const vendorOnboardingReducer = combineReducers<VendorStateType>({
  auth: authReducer,
  config: configReducer,
  phone: phoneReducer,
  payDest: payDestReducer,
  routes: routeReducer,
  fullName: fullNameReducer,
  dob: dobReducer,
  ssn: ssnReducer,
  address: addressReducer,
  email: emailReducer,
  kyb: combineReducers({
    sync: businessSyncReducer,
    business: combineReducers({
      info: businessInfoReducer,
      address: businessAddressReducer,
    }),
    admin: combineReducers({
      info: adminInfoReducer,
      address: adminAddressReducer,
    }),
    controller: combineReducers({
      info: businessControllerInfoReducer,
      address: businessControllerAddressReducer,
    }),
    owners: beneficialOwnersReducer,
  }),
  submit: submitInviteReducer,
});
export default vendorOnboardingReducer;

import { createAction } from "@reduxjs/toolkit";

const businessControllerAddressActions = {
  setAddressValue: createAction(
    "vendor/kyb/controller/address/setAddressValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  clearAddress: createAction("vendor/kyb/controller/address/clearAddress"),
};

export default businessControllerAddressActions;

import { Stack, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import StitchSuccessIcon from "@ob/components/StitchSuccessIcon";
import { FadeInStack } from "@ob/components/FadeComponents";

export default function DeleteSuccess() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <FadeInStack
      alignItems="center"
      justifyContent="space-between"
      sx={{ minHeight: "100vh", p: 3, pt: "50%" }}
    >
      <Stack alignItems="center" justifyContent="center">
        <StitchSuccessIcon />
        <Typography variant="h1">{"You're"} all set.</Typography>
        <Typography variant="body1" sx={{ mt: 6 }} align="center">
          Your account has been unlinked from your account.
        </Typography>
      </Stack>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        sx={{
          borderColor: theme.palette.secondary.light,
        }}
        onClick={() => navigate("/linked_accounts")}
      >
        Linked Accounts
      </Button>
    </FadeInStack>
  );
}

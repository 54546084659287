import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import GenericRetryError from "@ob/components/GenericRetryError";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectDob,
  selectDobError,
  selectDobFetching,
  selectDobSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/dob";
import formatBirthday from "@ob/utils/formatBirthday";
import { validateBirthday } from "@ob/utils/validation";

export default function Birthday() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    birthday: "",
  });

  const birthday = useAppSelector(selectDob);
  const apiFetching = useAppSelector(selectDobFetching);
  const apiError = useAppSelector(selectDobError);
  const apiSuccess = useAppSelector(selectDobSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  const handleSubmit = () => {
    const validBirthday = validateBirthday(
      formatBirthday(birthday),
      handleValidationResult("birthday"),
    );
    if (!validBirthday) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyc.dob.submitDOB());
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "/") {
        nextValue = value.slice(0, -2);
      }
      dispatch(va.kyc.dob.setDOB(nextValue));
    }
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.birthday,
      value.length === 10,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const value = e.target.value.replace(/\s+/g, "");
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    switch (name) {
      case "birthday":
        validateBirthday(value, handleValidationResult(name));
        break;
      default:
        break;
    }
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyc.dob.setDOB(nextValue));
    } else if (value.length <= 10) {
      dispatch(va.kyc.dob.setDOB(value));
    }
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const value = e.target.value.replace(/\s+/g, "");
    switch (name) {
      case "birthday":
        validateBirthday(value, handleValidationResult(name));
        break;
      default:
        break;
    }
    setShowErrors(true);
  };

  useEffect(
    () =>
      function umount() {
        dispatch(va.kyc.dob.apiSuccess(false));
        dispatch(va.kyc.dob.apiFetching(false));
        dispatch(
          va.kyc.dob.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  useEffect(() => {
    if (apiSuccess) {
      navigate("/kyc/ssn");
      dispatch(va.kyc.dob.apiFetching(false));
    }
  }, [apiSuccess]);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        width: "100%",
        maxWidth: "748px",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Date of birth</Typography>
        <Typography variant="body1">
          Enter your full date of birth to verify your identity.
        </Typography>
      </Stack>
      <Stack data-testid="phone-input" gap={2}>
        <Stack sx={{ minHeight: "75px" }}>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="MM / DD / YYYY"
            name="birthday"
            value={formatBirthday(birthday)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={handleOnBlur}
            className="birthday-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.birthday ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.birthday}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const kybSyncActions = {
  apiFetching: createAction(
    "vendor/kyb/sync/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/kyb/sync/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction(
    "vendor/kyb/sync/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  submitBusiness: createAction("vendor/kyb/sync/submitBusiness"),
};
export default kybSyncActions;

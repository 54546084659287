import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routes404 from "@ob/pages/Page404/routes";
import routes500 from "@ob/pages/Page500/routes";
import { isTestEnv } from "@ob/utils/envCheck";
import { useAppDispatch } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { extraLoadParams } from "@ob/layouts/VendorOnboarding/utils/constants";

async function loadChildren() {
  let children = [...routes404, ...routes500];
  if (process.env.STITCH_APP === "vendor") {
    const vendorRoutes = await import("@ob/layouts/VendorOnboarding/routes");
    children = children.concat(vendorRoutes.default);
  }
  if (isTestEnv()) {
    const devDashboard = await import("@ob/pages/DebuggerDash");
    const DevDashboard = devDashboard.default;
    children.push({
      path: "/debugger",
      element: <DevDashboard />,
    });
  }
  return children;
}

function MainRoute() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const auth = params.get("auth");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (auth) {
    dispatch(va.auth.setJWT(auth));
  }
  if (detectExtraParams(params)) {
    dispatch(va.auth.setExtraParams(params.toString()));
  }

  const { pathname } = location;
  useEffect(() => {
    if (pathname === "/") {
      navigate("/authorize", { replace: true });
    }
  }, [pathname, navigate]);

  return <Outlet />;
}

function detectExtraParams(params: URLSearchParams) {
  const hasParams = extraLoadParams.some((field) => params.get(field));
  return hasParams;
}

export default async function MainRoutes() {
  return {
    path: "/",
    element: <MainRoute />,
    errorElement: routes404[0].errorElement,
    children: await loadChildren(),
  };
}

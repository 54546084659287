import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import type { VendorConfigResType } from "@ob/api/types";

export const getConfig = async (jwt: string): Promise<VendorConfigResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/config/vendor?auth=${jwt}`,
    {
      method: "GET",
    },
  );
  return response;
};

export default getConfig;

import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import StitchLoading from "@ob/components/StitchLoading";
import { CheckCircleOutlined } from "@ant-design/icons";

type Props = {
  buttonText: string;
  buttonWidth?: string; // eslint-disable-line
  variant: "contained" | "outlined" | "text";
  color: "primary" | "secondary" | "inherit" | "error";
  logoColor: "white" | "black";
  loadingSize: "small" | "medium" | "large";
  loading: boolean;
  loadingPosition: { top: number; left: number };
  success: boolean;
  onClick: () => void;
};
export default function StitchAsyncButton(props: Props) {
  const { buttonWidth = "100%" } = props;
  const [showSuccess, setShowSuccess] = useState(false);

  let style: { [key: string]: string } = {
    height: "40px",
  };
  if (buttonWidth) {
    style = {
      ...style,
      width: buttonWidth,
    };
  }

  const { success: apiSuccess } = props;
  useEffect(() => {
    if (apiSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
  }, [apiSuccess]);

  const renderButtonContent = () => {
    if (props.loading) {
      return (
        <StitchLoading
          size={props.loadingSize}
          position={props.loadingPosition}
          color={props.logoColor}
        />
      );
    }

    if (showSuccess) {
      return <CheckCircleOutlined style={{ fontSize: "24px" }} />;
    }

    return props.buttonText;
  };

  return (
    <Button
      fullWidth={!buttonWidth}
      variant={props.variant}
      color={props.color}
      onClick={() => {
        if (!props.loading) props.onClick();
      }}
      style={style}
    >
      {renderButtonContent()}
    </Button>
  );
}

import { useState, useEffect } from "react";
import { CloseCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { endpoints } from "@ob/config/envSetup";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import { Stack, Typography, Button } from "@mui/material";
import StitchLoading from "@ob/components/StitchLoading";
import isDevEnv, { isTestEnv } from "@ob/utils/envCheck";
import { FadeInStack } from "@ob/components/FadeComponents";

import {
  selectPayDestFetching,
  selectPayDestError,
  selectClientId,
  selectSessionToken,
  selectBusinessProfileId,
  selectUserId,
  selectUserIntentId,
} from "../../redux/selectors/payDest";
import va from "../../redux/actions";
import { selectJWT } from "../../redux/selectors/auth";

export default function CreditCard() {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectPayDestFetching);
  const apiError = useAppSelector(selectPayDestError);
  const sessionToken = useAppSelector(selectSessionToken);
  const clientId = useAppSelector(selectClientId);
  const businessProfileId = useAppSelector(selectBusinessProfileId);
  const userId = useAppSelector(selectUserId);
  const userIntentId = useAppSelector(selectUserIntentId);
  const jwt = useAppSelector(selectJWT);

  let uniqueId = "";
  if (isDevEnv()) {
    uniqueId += `user_id=${Date.now()}`;
  } else if (userId) {
    uniqueId = `user_id=${userId}`;
  } else if (businessProfileId) {
    uniqueId = `business_profile_id=${businessProfileId}`;
  } else if (userIntentId) {
    uniqueId = `user_intent_id=${userIntentId}`;
  }
  const [uiFetching, setUiFetching] = useState(true);
  const hasMissingParams = !clientId || !sessionToken || !uniqueId;

  const handleRetry = () => {
    if (!apiFetching) {
      dispatch(va.payDest.getCardReqParams());
    }
  };

  useEffect(() => {
    dispatch(va.payDest.getCardReqParams());

    return () => {
      dispatch(va.payDest.apiError({ status: 0, message: "" }));
      dispatch(va.payDest.apiFetching(false));
    };
  }, []);

  useEffect(() => {
    if (apiError.status && uiFetching) {
      setUiFetching(false);
    } else if (uiFetching && !apiFetching && !hasMissingParams) {
      setUiFetching(false);
    }
  }, [apiFetching, hasMissingParams]);

  if (apiFetching || uiFetching) {
    return (
      <FadeInStack alignItems="center" justifyContent="center">
        <StitchLoading />
      </FadeInStack>
    );
  }

  if (apiError.status) {
    return (
      <FadeInStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Stack gap={5} alignItems="center">
          <CloseCircleOutlined style={{ fontSize: "48px" }} />
          <Typography variant="h1" align="center">
            Oops.
          </Typography>
          <Typography variant="body1" align="center">
            {apiError.message || (
              <>
                Looks like something went wrong. <br /> Please try again.
              </>
            )}
          </Typography>
          <Stack gap={1} style={{ width: "100%" }}>
            <Button
              variant="contained"
              onClick={() => handleRetry()}
              sx={{ display: apiError.status === 400 ? "none" : "block" }}
            >
              Retry
            </Button>
          </Stack>
        </Stack>
      </FadeInStack>
    );
  }

  if (hasMissingParams && !apiError.status) {
    return (
      <FadeInStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          height: "100%",
          p: 3,
        }}
      >
        <Stack gap={5} alignItems="center">
          <QuestionCircleOutlined style={{ fontSize: "48px" }} />
          <Typography variant="h1" align="center">
            Slow Connection
          </Typography>
          <Typography variant="body1" align="center">
            {apiError.message || (
              <>
                {"We're"} trying to setup your Credit Card but {"we're"} missing
                some required information.
                <br /> Please check your network connection and Try again.
              </>
            )}
          </Typography>
          <Stack gap={1} style={{ width: "100%" }}>
            <Button
              variant="contained"
              onClick={() => handleRetry()}
              sx={{ display: apiError.status === 400 ? "none" : "block" }}
            >
              Retry
            </Button>
          </Stack>
        </Stack>
      </FadeInStack>
    );
  }
  const srcUrl = [
    `https://app${isTestEnv() ? "-sandbox" : ""}.astra.finance/cards/connect`,
    `?client_id=${clientId}`,
    `&${uniqueId}`,
    `&redirect_uri=${endpoints.astraRedirect}/astra_redirect`,
    `&debit_direct=true`,
    `&state=${jwt}`,
    `&session_token=${sessionToken}`,
  ].join("");
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", backgroundColor: "#FFF" }}
    >
      <iframe
        style={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "#FFF",
          paddingBottom: "50px",
        }}
        src={srcUrl}
        title="Credit Card Form"
        frameBorder="0"
        allowFullScreen
      />
    </Stack>
  );
}

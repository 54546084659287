import {
  CreditCardOutlined,
  BankOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  ButtonBase,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FadeInStack } from "@ob/components/FadeComponents";
import { selectMerchantName } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { useAppSelector } from "@ob/redux/store";
import { useNavigate } from "react-router";

export default function NoAccountMenu() {
  const navigate = useNavigate();
  const theme = useTheme();
  const merchantName = useAppSelector(selectMerchantName);

  return (
    <FadeInStack
      gap={1}
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        p: 3,
        flexGrow: 1,
        width: "100%",
        maxWidth: "748px",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Typography variant="h2">Choose an account</Typography>
        <Typography variant="body1">
          Link a debit card or bank account to {merchantName} for transfers.
        </Typography>
      </Stack>
      <ButtonBase
        sx={{
          alignItems: "flex-start",
          width: "100%",
        }}
        onClick={() => navigate("/credit_card")}
      >
        <Stack
          gap={1}
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            width: "100%",
            pt: 2,
            minHeight: 5,
            mt: 4,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <CreditCardOutlined style={{ fontSize: "24px" }} />
              <Stack sx={{ ml: 2, width: "100%" }} justifyContent="flex-start">
                <Typography variant="body1" align="left">
                  Debit Card
                </Typography>
                <Typography variant="body1" align="left" color="secondary">
                  Instant Transfers
                </Typography>
              </Stack>
            </Stack>
            <RightOutlined style={{ fontSize: "12px" }} />
          </Stack>
          <Divider
            sx={{
              my: 1,
              width: "100%",
              borderBottomColor: theme.palette.secondary.light,
            }}
          />
        </Stack>
      </ButtonBase>
      <ButtonBase
        sx={{
          alignItems: "flex-start",
          width: "100%",
        }}
        onClick={() => navigate("/bank_account", { replace: true })}
      >
        <Stack
          gap={1}
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            width: "100%",
            pt: 2,
            minHeight: 5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <BankOutlined style={{ fontSize: "24px" }} />
              <Stack sx={{ ml: 2, width: "100%" }} justifyContent="flex-start">
                <Typography variant="body1" align="left">
                  Bank Account
                </Typography>
                <Typography variant="body1" align="left" color="secondary">
                  Typically 1-3 business days
                </Typography>
              </Stack>
            </Stack>
            <RightOutlined style={{ fontSize: "12px" }} />
          </Stack>
          <Divider
            sx={{
              my: 1,
              width: "100%",
              borderBottomColor: theme.palette.secondary.light,
            }}
          />
        </Stack>
      </ButtonBase>
    </FadeInStack>
  );
}

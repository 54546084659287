import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import GenericRetryError from "@ob/components/GenericRetryError";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectCity,
  selectState,
  selectStreet1,
  selectStreet2,
  selectZip,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/address";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import {
  validateStreet1,
  validateStreet2,
  validateCity,
  validateState,
  validateZip,
} from "@ob/utils/validation";
import USStateSelect from "@ob/components/USStateSelect";

export default function BusinessControllerAddress() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });
  const hasErrors =
    uiErrors.street1 ||
    uiErrors.street2 ||
    uiErrors.city ||
    uiErrors.state ||
    uiErrors.zip;

  const street1 = useAppSelector(selectStreet1);
  const street2 = useAppSelector(selectStreet2);
  const state = useAppSelector(selectState);
  const city = useAppSelector(selectCity);
  const zip = useAppSelector(selectZip);
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const validAddress = [
      validateStreet1(street1, handleValidationResult("street1")),
      validateStreet2(street2, handleValidationResult("street2")),
      validateCity(city, handleValidationResult("city")),
      validateState(state, handleValidationResult("state")),
      validateZip(zip, handleValidationResult("zip")),
    ].every((error) => error);
    if (!validAddress) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyb.sync.submitBusiness());
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "street1":
        return validateStreet1(value, handleValidationResult(name));
      case "street2":
        return validateStreet2(value, handleValidationResult(name));
      case "city":
        return validateCity(value, handleValidationResult(name));
      case "state":
        return validateState(value, handleValidationResult(name));
      case "zip":
        return validateZip(value, handleValidationResult(name));
      default:
        break;
    }
    return false;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    dispatch(va.kyb.controller.address.setAddressValue(name, value));
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      !uiErrors.street1,
      !uiErrors.city,
      !uiErrors.state,
      !uiErrors.zip,
      street1,
      city,
      state,
      zip,
      !apiFetching,
      e.key === "Enter",
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (apiSuccess) {
      dispatch(va.kyb.sync.apiSuccess(false));
      dispatch(va.kyb.sync.apiFetching(false));
      dispatch(
        va.kyb.sync.apiError({
          status: 0,
          message: "",
        }),
      );
      navigate("/kyb/business_controller_beneficial_owner_check");
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  useEffect(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  useEffect(
    () =>
      function unmount() {
        dispatch(va.kyb.sync.apiSuccess(false));
        dispatch(va.kyb.sync.apiFetching(false));
        dispatch(
          va.kyb.sync.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={87}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Business controller</Typography>
        <Typography variant="body1">
          Provide the information for this representative as it has been
          affiliated with the business.
        </Typography>
      </Stack>
      <Stack data-testid="address" gap={1}>
        <Stack>
          <Typography variant="body1">Address</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Street 1"
            name="street1"
            value={street1}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            onInput={() => setUiErrors((prev) => ({ ...prev, street1: "" }))}
            className="address-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.street1 && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.street1 ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.street1}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Street 2"
            name="street2"
            value={street2}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="address-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.street2 && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.street2 ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.street2}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="City"
            name="city"
            value={city}
            autoCorrect={"off"} // disabled to prevent state autocomplete from crashing
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, city: "" }))}
            onKeyDown={handleOnKeyDown}
            className="address-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.city && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.city ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.city}
          </Typography>
        </Stack>
        <Stack>
          <USStateSelect onChange={handleOnChange} initialStateCode={state} />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.state ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.state}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Zip code"
            name="zip"
            value={zip}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, zip: "" }))}
            onKeyDown={handleOnKeyDown}
            className="address-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.zip && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.zip ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.zip}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import { RightOutlined } from "@ant-design/icons";
import { ButtonBase, Stack, Typography } from "@mui/material";

type Props = {
  onAdd: () => void;
};

export default function BusinessOwnerAddRow(props: Props) {
  return (
    <ButtonBase
      sx={{
        alignItems: "flex-start",
        pt: "15px",
        pb: "15px",
      }}
      onClick={props.onAdd}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography
          variant="body1"
          align="left"
          sx={{ width: "80%", whiteSpace: "initial" }}
        >
          Add any individuals who own &ge;25% of the business.
        </Typography>
        <RightOutlined style={{ fontSize: "12px" }} />
      </Stack>
    </ButtonBase>
  );
}

import { Autocomplete, TextField, useTheme } from "@mui/material";
import usStates from "@ob/utils/states";
import { useState } from "react";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialStateCode?: string;
  idPrefix?: string;
};

export default function USStateSelect({
  initialStateCode = "",
  idPrefix = "",
  ...props
}: Props) {
  const theme = useTheme();
  const [selectedStateCode, setSelectedStateCode] = useState(initialStateCode);
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id={`${idPrefix}_state-select`}
      // NOTE: The following hack gets around Typescript complaining about null value typing
      // in Autocomplete. Without it, the `value` attribute will give an error:
      // "Type 'StateType | null' is not assignable to type 'StateType | undefined'."
      // And it can't default to `undefined` because if this component is initialized
      // as `undefined`, MUI will error in the console:
      // "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled.
      // Elements should not switch from uncontrolled to controlled (or vice versa).
      // Decide between using a controlled or uncontrolled Autocomplete element for the lifetime of the component.
      // The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.
      // More info: https://fb.me/react-controlled-components"
      disableClearable={Boolean(1)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(_, value) => {
        const stateCode = value?.code || "";
        setSelectedStateCode(stateCode);
        props.onChange({
          target: {
            name: "state",
            value: stateCode,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      onKeyDown={(
        e: React.KeyboardEvent<HTMLDivElement> & {
          defaultMuiPrevented?: boolean | undefined;
        },
      ) => {
        if (e.key === "Enter") {
          const typedValue = (
            document.getElementById(
              `${idPrefix}_state-select`,
            ) as HTMLInputElement
          ).value;
          const targetState = usStates.find(
            (s) => s.code === typedValue.toUpperCase(),
          );
          if (targetState) {
            setSelectedStateCode(targetState.code);
            props.onChange({
              target: {
                name: "state",
                value: targetState.code,
              },
            } as React.ChangeEvent<HTMLInputElement>);
            setOpen(false);
          }
        }
      }}
      autoSelect
      onInputChange={(_, inputValue, reason) => {
        if (reason === "reset") {
          const targetState = usStates.find(
            (s) => `${s.name} (${s.code})` === inputValue,
          );
          props.onChange({
            target: {
              name: "state",
              value: targetState?.code || "",
            },
          } as React.ChangeEvent<HTMLInputElement>);
        } else {
          const intendedState = usStates.find(
            (s) => s.code === inputValue.toUpperCase(),
          );
          if (intendedState) {
            props.onChange({
              target: {
                name: "state",
                value: intendedState.code,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }
        }
      }}
      value={usStates.find((s) => s.code === selectedStateCode) || undefined}
      options={usStates.sort((a, b) => -b.code.localeCompare(a.code))}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `${option.name} (${option.code})`}
      renderOption={(renderProps, option) => {
        if (option.code) {
          return (
            <li
              {...renderProps}
              key={option.code}
              data-option-code={option.code}
            >
              {`${option.name} (${option.code})`}
            </li>
          );
        }
        return "";
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="State"
          style={{
            color: theme.palette.primary.main,
            backgroundColor: "transparent",
            borderRadius: "4px",
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "CircularRegular",
            width: "100%",
          }}
        />
      )}
      sx={{
        overflowX: "auto",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "6px",
          height: "7px",
        },
        "::-webkit-scrollbar-track": {
          background: "#F1F1F1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#333",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#333",
        },
      }}
    />
  );
}

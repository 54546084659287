import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { PhoneStateType } from "@ob/layouts/VendorOnboarding/types/phone";
import va from "../../actions";

export const initPhoneState = createAsyncSlice({
  phoneNumber: "",
  phoneCode: "",
  otpValid: false,
});

const phoneReducer = createReducer<PhoneStateType>(
  initPhoneState,
  (builder) => {
    builder
      .addCase(va.phone.apiFetching, onApiFetching)
      .addCase(va.phone.apiError, onApiError)
      .addCase(va.phone.apiSuccess, onApiSuccess)
      .addCase(va.phone.setPhoneValue, onSetPhoneValue)
      .addCase(va.phone.clearPhoneCode, onClearPhoneCode)
      .addCase(va.phone.clearPhoneNumber, onClearPhoneNumber)
      .addCase(va.phone.setOtpValid, onSetOtpValid)
      .addCase(va.phone.retryPhone, onRetryPhone)
      .addCase(va.phone.resendPhoneCode, onResendPhoneCode);
  },
);
export default phoneReducer;

export function onApiFetching(
  state: PhoneStateType,
  action: ReturnType<typeof va.phone.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: PhoneStateType,
  action: ReturnType<typeof va.phone.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: PhoneStateType,
  action: ReturnType<typeof va.phone.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetPhoneValue(
  state: PhoneStateType,
  action: ReturnType<typeof va.phone.setPhoneValue>,
) {
  if (action.payload.key in state.data) {
    state.data = {
      ...state.data,
      [action.payload.key]: action.payload.value,
    };
  } else if (action.payload.key.includes("phoneCode")) {
    const index = Number(action.payload.key.split(".")[1]);
    const oldPhoneCode: string[] = state.data.phoneCode.split("");
    oldPhoneCode[index] = action.payload.value;
    state.data.phoneCode = oldPhoneCode.join("");
  }
}

export function onClearPhoneCode(state: PhoneStateType) {
  state.data.phoneCode = "";
}
export function onClearPhoneNumber(state: PhoneStateType) {
  state.data.phoneNumber = "";
}

export function onSetOtpValid(
  state: PhoneStateType,
  action: ReturnType<typeof va.phone.setOtpValid>,
) {
  state.data.otpValid = action.payload.otpValid;
}

export function onRetryPhone(state: PhoneStateType) {
  state.success = false;
  state.error = { message: "", status: 0 };
}

export function onResendPhoneCode(state: PhoneStateType) {
  state.data.phoneCode = "";
}

import { all, fork } from "redux-saga/effects";
import authSaga from "@ob/layouts/VendorOnboarding/redux/sagas/auth";
import configSaga from "./config";
import dobSaga from "./kyc/address";
import addressSaga from "./kyc/dob";
import fullNameSaga from "./kyc/fullName";
import phoneSaga from "./phone";
import emailSaga from "./kyc/email";
import ssnSaga from "./kyc/ssn";
import payDestSaga from "./payDest";
import businessSyncSaga from "./kyb/sync";
import submitInviteSaga from "./submit";

export default function* vendorRootSaga() {
  yield all(
    [
      authSaga,
      configSaga,
      addressSaga,
      dobSaga,
      fullNameSaga,
      phoneSaga,
      emailSaga,
      ssnSaga,
      payDestSaga,
      businessSyncSaga,
      submitInviteSaga,
    ].map(fork),
  );
}

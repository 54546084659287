import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import usStates from "@ob/utils/states";

export function validateFirstName(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, Unicode basic Latin letters, Unicode Latin extended-A letters,
  // apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .,-]+$`);
  if (!value) {
    cb("First name is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid first name");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateLastName(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, Unicode basic Latin letters, Unicode Latin extended-A letters,
  // apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .,-]+$`);
  if (!value) {
    cb("Last name is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid last Name");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateEmail(
  value: string,
  cb: (error: string) => void,
): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim;
  if (!value) {
    cb("Email is required.");
  } else if (value && !emailRegex.test(value)) {
    cb("Invalid Email. Please use the format 'address@site.domain'");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateSSN(value: string, cb: (error: string) => void) {
  const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/gim;
  if (!value) {
    cb("SSN is required.");
  } else if (value && !ssnRegex.test(value)) {
    cb("Invalid SSN. Please use the format 123-45-6789.");
  } else if (value.length > 11) {
    cb("SSN must be 11 characters long.");
  } else if (value.length === 11) {
    cb("");
    return true;
  }
  return false;
}

export function validateBirthday(
  _value: string,
  cb: (error: string) => void,
): boolean {
  const value = _value.replace(/\s+/g, "");
  const birthdayRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;
  if (!value) {
    cb("Date of birth is required");
  } else if (value && !birthdayRegex.test(value)) {
    cb("Invalid birthday. Please use the format MM/DD/YYYY");
  } else if (value && birthdayRegex.test(value)) {
    const date = new Date(value);
    const today = new Date();
    if (date > today) {
      cb("Invalid birthday. Please use a date in the past");
    } else {
      cb("");
      return true;
    }
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateBusinessName(
  value: string,
  cb: (error: string) => void,
) {
  // NOTE: Allow any alphanumeric, Unicode basic Latin letters, Unicode Latin extended-A letters,
  // apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .,-]+$`);
  if (!value) {
    cb("Business name is required");
  } else if (value && value.length > 64) {
    cb("Business name is too long");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid business name");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateDoingBusinessAs(
  value: string,
  cb: (error: string) => void,
) {
  // NOTE: Allow any alphanumeric, Unicode basic Latin letters, Unicode Latin extended-A letters,
  // apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' .,-]+$`);
  if (value && value.length > 64) {
    cb("Preferred name of business is too long");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid preferred name of business");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateEin(value: string, cb: (error: string) => void) {
  const einRegex = /^[0-9]{2}-[0-9]{7}$/gim;
  if (value && !einRegex.test(value)) {
    cb("Invalid EIN. Please use the format 12-1234567.");
    return false;
  }
  cb("");
  return true;
}

export function validateRequiredEin(
  ein: string,
  businessType: BusinessStructureType,
  cb: (error: string) => void,
) {
  if (!ein && businessType !== BusinessStructureType.SOLE_PROPRIETORSHIP) {
    cb("EIN is required if business type is not a sole proprietorship");
    return false;
  }
  cb("");
  return true;
}

export function validateBusinessStructure(
  value: BusinessStructureType | null,
  cb: (error: string) => void,
) {
  if (!value) {
    cb("Business Type is required");
    return false;
  }
  cb("");
  return true;
}

export function validateStreet1(value: string, cb: (error: string) => void) {
  const street1Chars = /[a-zA-Z]+/;
  // NOTE: Allow any alphanumeric, letters with accents and umlauts, apostrophes ('), dashes (-), periods (.), and spaces.
  const street1All = /^[A-Za-zÀ-ÿ0-9-\s.']+$/;
  if (!value) {
    cb("Street 1 is required");
  } else if (value && !street1Chars.test(value)) {
    cb("Invalid street 1. Should contain letters.");
  } else if (value && value.length < 3) {
    cb("Street 1 must be at least 3 characters");
  } else if (value && !street1All.test(value)) {
    cb("Invalid street 1");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateStreet2(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, letters with accents and umlauts, apostrophes ('), dashes (-), periods (.), and spaces.
  const street2Regex = /^[A-Za-zÀ-ÿ0-9-\s.']+$/;
  if (value && !street2Regex.test(value)) {
    cb("Invalid street 2");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateCity(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, letters with accents and umlauts, apostrophes ('), dashes (-), periods (.), and spaces.
  const cityRegex = /^[A-Za-zÀ-ÿ0-9-\s.']+$/;
  const cityNums = /[0-9]+/;
  if (!value) {
    cb("City is required");
  } else if (value && value.length < 3) {
    cb("City must be at least 3 characters");
  } else if (value && cityNums.test(value)) {
    cb("Invalid city. Should not contain numbers.");
  } else if (value && !cityRegex.test(value)) {
    cb("Invalid city");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateState(value: string, cb: (error: string) => void) {
  const stateRegex = /^[a-zA-Z+]{2}$/;
  if (!value) {
    cb("State is required");
  } else if (value && !stateRegex.test(value)) {
    cb("Invalid state code");
  } else if (value && !usStates.find(({ code }) => code === value)) {
    cb("Invalid state code");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateZip(value: string, cb: (error: string) => void) {
  const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
  const zipChars = /[a-zA-Z]+/;
  if (!value) {
    cb("Zip code is required");
  } else if (value && value.length < 5) {
    cb("Zip code must be at least 5 characters");
  } else if (value && zipChars.test(value)) {
    cb("Invalid zip code. Should not contain letters.");
  } else if (value && !zipRegex.test(value)) {
    cb("Invalid zip code. Please use the format 12345 or 12345-6789");
  } else {
    cb("");
    return true;
  }
  return false;
}

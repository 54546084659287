import { useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import {
  selectConfigApiSuccess,
  selectKybConfigEnabled,
} from "@ob/layouts/VendorOnboarding/redux/selectors/config";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

type KybConfigGuardProps = {
  redirectPath?: string;
  children: ReactElement | null;
};

const KybConfigGuard = ({
  redirectPath = "/kyc/name",
  ...props
}: KybConfigGuardProps) => {
  const kybConfigEnabled = useAppSelector(selectKybConfigEnabled);
  const configApiSuccess = useAppSelector(selectConfigApiSuccess);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(va.config.getConfig());
  }, []);

  useEffect(() => {
    if (configApiSuccess && kybConfigEnabled === false) {
      navigate(redirectPath, {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }
  }, [kybConfigEnabled, configApiSuccess, navigate, location]);

  return props.children;
};

export default KybConfigGuard;

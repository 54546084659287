import { createReducer } from "@reduxjs/toolkit";
import vendorRouteActions from "@ob/layouts/VendorOnboarding/redux/actions/routes";
import { RouteStateType } from "@ob/layouts/VendorOnboarding/types/routes";

const initialRouteState: RouteStateType = {
  nextRoute: "",
};

const routeReducer = createReducer<RouteStateType>(
  initialRouteState,
  (builder) => {
    builder
      .addCase(vendorRouteActions.redirect, onRedirect)
      .addCase(vendorRouteActions.clearNextRoute, onClearNextRoute);
  },
);

export default routeReducer;

export function onRedirect(
  state: RouteStateType,
  action: ReturnType<typeof vendorRouteActions.redirect>,
) {
  state.nextRoute = action.payload.route;
}

export function onClearNextRoute(state: RouteStateType) {
  state.nextRoute = "";
}

import { createReducer } from "@reduxjs/toolkit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  BusinessInfoStateType,
  BusinessStructureType,
} from "@ob/layouts/VendorOnboarding/types/kyb/business/info";

export const initBusinessInfoStateType = {
  businessName: "",
  doingBusinessAs: "",
  ein: "",
  businessStructure: BusinessStructureType.NONE,
};

const businessInfoReducer = createReducer<BusinessInfoStateType>(
  initBusinessInfoStateType,
  (builder) => {
    builder
      .addCase(va.kyb.business.info.setBusinessName, onSetBusinessName)
      .addCase(va.kyb.business.info.clearBusinessName, onClearBusinessName)
      .addCase(va.kyb.business.info.setDoingBusinessAs, onSetDoingBusinessAs)
      .addCase(
        va.kyb.business.info.clearDoingBusinessAs,
        onClearDoingBusinessAs,
      )
      .addCase(va.kyb.business.info.setEin, onSetEin)
      .addCase(va.kyb.business.info.clearEin, onClearEin)
      .addCase(
        va.kyb.business.info.setBusinessStructure,
        onSetBusinessStructure,
      )
      .addCase(
        va.kyb.business.info.clearBusinessStructure,
        onClearBusinessStructure,
      );
  },
);
export default businessInfoReducer;

export function onSetBusinessName(
  state: BusinessInfoStateType,
  action: ReturnType<typeof va.kyb.business.info.setBusinessName>,
) {
  state.businessName = action.payload.value;
}

export function onClearBusinessName(state: BusinessInfoStateType) {
  state.businessName = "";
}

export function onSetDoingBusinessAs(
  state: BusinessInfoStateType,
  action: ReturnType<typeof va.kyb.business.info.setDoingBusinessAs>,
) {
  state.doingBusinessAs = action.payload.value;
}

export function onClearDoingBusinessAs(state: BusinessInfoStateType) {
  state.doingBusinessAs = "";
}

export function onSetEin(
  state: BusinessInfoStateType,
  action: ReturnType<typeof va.kyb.business.info.setEin>,
) {
  state.ein = action.payload.value;
}

export function onClearEin(state: BusinessInfoStateType) {
  state.ein = "";
}

export function onSetBusinessStructure(
  state: BusinessInfoStateType,
  action: ReturnType<typeof va.kyb.business.info.setBusinessStructure>,
) {
  state.businessStructure = action.payload.value;
}

export function onClearBusinessStructure(state: BusinessInfoStateType) {
  state.businessStructure = BusinessStructureType.NONE;
}

import type { Store } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  Persistor,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import getReducersByApp from "./reducers";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [], // NOTE: You can turn this one manually in dev mode if you want to persist some data.
};

const rootReducer = getReducersByApp();
export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const persistMiddleware = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};
type PersistApiType = {
  purge: () => Promise<void>;
  flush: () => Promise<void>;
  pause: () => void;
  persist: () => void;
};

function setupPersistApi(persistor: Persistor): PersistApiType {
  return {
    purge: (): Promise<void> =>
      new Promise((resolve, reject) => {
        persistor.purge().then(resolve).catch(reject);
      }),
    flush: (): Promise<void> =>
      new Promise((resolve, reject) => {
        persistor.flush().then(resolve).catch(reject);
      }),
    pause: (): void => {
      persistor.pause();
    },
    persist: (): void => {
      persistor.persist();
    },
  };
}

export default function persistReduxStore(store: Store): {
  persistor: Persistor;
  persistApi: PersistApiType;
} {
  const persistor = persistStore(store);
  const persistApi = setupPersistApi(persistor);
  return {
    persistor,
    persistApi,
  };
}

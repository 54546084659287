export default function formatBirthday(birthday: string): string {
  if (!birthday) {
    return "";
  }
  const match = birthday.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (match) {
    return `${match[2]} / ${match[3]} / ${match[1]}`;
  }

  const b = birthday.replace(/\D/g, "");
  if (b.length === 2) {
    return `${b} / `;
  }
  if (b.length > 2 && b.length < 4) {
    return `${b.slice(0, 2)} / ${b.slice(2)}`;
  }
  if (b.length >= 4) {
    return `${b.slice(0, 2)} / ${b.slice(2, 4)} / ${b.slice(4, 8)}`;
  }
  return b;
}

export function formatApiBirthday(dob: string): string {
  let dobFormatted: string = "";
  try {
    [dobFormatted] = new Date(dob).toISOString().split("T");
  } catch (e) {
    console.warn("Could not parse date", dob);
  }
  return dobFormatted;
}

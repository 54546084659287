import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";

import { KyvUserTypes, UpdateInviteResType } from "@ob/api/types";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { selectJWT } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { selectSSN } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/ssn";

export default function* ssnSaga() {
  yield takeLatest(va.kyc.ssn.submitSSN, onSubmitSSN);
}

export function* onSubmitSSN() {
  try {
    const ssn: string = yield select(selectSSN);
    if (!ssn) return;
    yield all([
      put(va.kyc.ssn.apiFetching(true)),
      put(
        va.kyc.ssn.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const jwt: string = yield select(selectJWT);
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      { tax_id: ssn },
      KyvUserTypes.INDIVIDUAL,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyc.ssn.apiError(res.error)),
          put(va.kyc.ssn.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.kyc.ssn.apiError(res.error)),
          put(va.kyc.ssn.apiFetching(false)),
        ]);
      }
    } else {
      yield delay(400);
      yield all([
        put(va.kyc.ssn.apiSuccess(true)),
        put(va.kyc.ssn.apiFetching(false)),
        put(va.kyc.ssn.clearSSN()),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your SSN. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyc.ssn.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyc.ssn.apiFetching(false)),
    ]);
  }
}

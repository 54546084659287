import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import type {
  BankInfoReqType,
  GetCardsResType,
  GetAcctsResType,
  DeleteCardResType,
  DeleteBankResType,
  CreateAcctResType,
} from "@ob/api/types";

export const fetchGetCards = async (jwt: string): Promise<GetCardsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/cards?auth=${jwt}`,
    { method: "GET" },
  );
  return response;
};

export const fetchGetAccts = async (jwt: string): Promise<GetAcctsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/accounts?auth=${jwt}`,
    { method: "GET" },
  );
  return response;
};

export const fetchDeleteCard = async (
  jwt: string,
  cardId: string,
): Promise<DeleteCardResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/cards/${cardId}?auth=${jwt}`,
    { method: "DELETE" },
  );
  return response;
};

export const fetchDeleteAcct = async (
  jwt: string,
  bankId: string,
): Promise<DeleteBankResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/accounts/${bankId}?auth=${jwt}`,
    { method: "DELETE" },
  );
  return response;
};

export const fetchCreateAcct = async (
  jwt: string,
  bankInfo: BankInfoReqType,
): Promise<CreateAcctResType> => {
  const name = [
    bankInfo.accountType[0].toUpperCase(),
    bankInfo.accountType.slice(1),
  ]
    .join("")
    .concat(" ")
    .concat(bankInfo.accountNumber.slice(-4));

  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/accounts?auth=${jwt}`,
    {
      method: "POST",
      body: JSON.stringify({
        name,
        type: bankInfo.accountType,
        account_number: bankInfo.accountNumber,
        routing_number: bankInfo.routingNumber,
      }),
    },
  );
  return response;
};

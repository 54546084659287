import { Link } from "react-router-dom";

// material-ui
import { Button, Stack, Typography } from "@mui/material";

// assets
import bgGrid from "@ob/assets/images/maintenance/bg-grid.png";
import svg500 from "@ob/assets/svgs/500.svg";
import isDevEnv from "@ob/utils/envCheck";

// ==============================|| ERROR 404 - MAIN ||============================== //

export default function Page500() {
  return (
    <Stack
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundImage: `url(${bgGrid})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: 250, sm: 590 },
          height: { xs: 130, sm: 300 },
        }}
      >
        <img
          src={svg500}
          alt="500-server-down"
          style={{
            maxWidth: "300px",
            width: "100%",
            height: "100%",
          }}
        />
      </Stack>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography
          color="primary"
          align="center"
          variant="h4"
          sx={{ width: { xs: "73%", sm: "61%" } }}
        >
          Server Error
        </Typography>
        <Typography
          color="primary"
          align="center"
          variant="body1"
          sx={{ width: { xs: "73%", sm: "61%" } }}
        >
          Something went wrong on our end.
          <br />
          Try refreshing the page or trying again later.
        </Typography>
        {isDevEnv() ? (
          <Button component={Link} to="/debugger" variant="outlined">
            Debugger
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

type Props = {
  error: ErrorPayload | null;
};
export default function InfoMsg(props: Props) {
  if (props.error?.status || props.error?.message) {
    return null;
  }

  return (
    <Stack>
      <Stack>
        <Typography variant="body2" color="secondary" align="center">
          Your phone number will be used to verify your contact information. We
          are required by law to collect this information to authorize payments.
          We will never share this information with marketers and we will never
          send you spam.
        </Typography>
      </Stack>
    </Stack>
  );
}

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Typography, Button, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FadeInStack } from "@ob/components/FadeComponents";
import GenericRetryError from "@ob/components/GenericRetryError";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { selectControllerInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/info";
import { selectControllerAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/address";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import { selectAdminInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/info";
import { selectAdminAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/address";
import { selectBeneficialOwners } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/owner";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { SingleBeneficialOwnerStateType } from "@ob/layouts/VendorOnboarding/types/kyb/owners";
import BusinessPersonRow from "./components/BusinessPersonRow";
import BeneficialOwners from "./components/BeneficialOwners";

export default function Review() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);

  const adminInfo = useAppSelector(selectAdminInfo);
  const adminAddress = useAppSelector(selectAdminAddress);
  const controllerInfo = useAppSelector(selectControllerInfo);
  const controllerAddress = useAppSelector(selectControllerAddress);
  const owners = useAppSelector(selectBeneficialOwners);

  const [showSubmitError, setShowSubmitError] = useState(false);

  const handleEditAdmin = () => {
    navigate("/kyb/admin_info");
  };

  const handleEditController = () => {
    navigate("/kyb/business_controller_info");
  };

  const handleAddBeneficialOwner = () => {
    dispatch(va.kyb.owners.addBeneficialOwner());
    navigate("/kyb/owners_info");
  };

  const handleEditBeneficialOwner = (index: number) => {
    dispatch(va.kyb.owners.setActiveIndex(index));
    navigate(`/kyb/owners_info/${index}`);
  };

  const handleSubmit = () => {
    if (owners.length > 0) {
      navigate("/submit/business");
    }
  };

  const removeEmptyBusinessOwners = useCallback(
    (_owners: SingleBeneficialOwnerStateType[]) => {
      _owners
        .reduce((acc: number[], owner, i) => {
          const shouldRemove = [
            !owner.info.firstName,
            !owner.info.lastName,
            !owner.info.email,
            !owner.info.dob,
            !owner.info.ssn,
            !owner.info.title,
            !owner.address.street1,
            !owner.address.street2,
            !owner.address.city,
            !owner.address.state,
            !owner.address.zip,
          ].every(Boolean);
          if (shouldRemove) {
            acc.push(i);
          }
          return acc;
        }, [])
        .forEach((ix) => dispatch(va.kyb.owners.removeBeneficialOwner(ix)));
    },
    [],
  );

  useEffect(() => {
    removeEmptyBusinessOwners(owners);
  }, []);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={99}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Review representatives</Typography>
        <Typography variant="body1">
          In compliance with regulatory guidelines, we are required to collect
          beneficial owners of the business.
        </Typography>
      </Stack>
      <Stack
        gap={1}
        sx={{
          py: 2,
          overflowY: "auto",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          height: "calc(100vh - 367px)",
          "& .MuiAutocomplete-popper": {
            "::-webkit-scrollbar": {
              width: "12px",
              height: "7px",
            },
            "::-webkit-scrollbar-track": {
              background: "#F1F1F1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#ddd",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#333",
            },
          },
        }}
      >
        <Stack>
          <Typography sx={{ fontFamily: "CircularBold" }}>You</Typography>
          <BusinessPersonRow
            firstName={adminInfo.firstName}
            lastName={adminInfo.lastName}
            title={adminInfo.title}
            street1={adminAddress.street1}
            street2={adminAddress.street2}
            city={adminAddress.city}
            state={adminAddress.state}
            zip={adminAddress.zip}
            onEditRow={handleEditAdmin}
          />
        </Stack>
        <Stack>
          <Typography sx={{ fontFamily: "CircularBold" }}>
            Business controller
          </Typography>
          <BusinessPersonRow
            firstName={controllerInfo.firstName}
            lastName={controllerInfo.lastName}
            title={controllerInfo.title}
            street1={controllerAddress.street1}
            street2={controllerAddress.street2}
            city={controllerAddress.city}
            state={controllerAddress.state}
            zip={controllerAddress.zip}
            onEditRow={handleEditController}
          />
        </Stack>
        <BeneficialOwners
          owners={owners}
          onAdd={handleAddBeneficialOwner}
          onEdit={handleEditBeneficialOwner}
        />
        <Stack sx={{ minHeight: "100px" }} />
      </Stack>
      <Stack gap={2} sx={{ height: "50px" }}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <Typography
          variant="subtitle1"
          color="error"
          sx={{
            display: showSubmitError ? "block" : "none",
            lineHeight: "16px",
          }}
        >
          You must provide between 1 and 4 beneficial owners.
        </Typography>
        {owners.length > 0 ? (
          <StitchAsyncButton
            buttonText="Submit"
            variant="contained"
            color="primary"
            logoColor="white"
            onClick={handleSubmit}
            loading={apiFetching}
            success={apiSuccess}
            loadingSize="small"
            loadingPosition={{ top: -31, left: 0 }}
          />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
              setShowSubmitError(true);
            }}
          >
            Submit
          </Button>
        )}
      </Stack>
    </FadeInStack>
  );
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorAddressActions = {
  apiFetching: createAction(
    "vendor/kyc/address/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "vendor/kyc/address/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "vendor/kyc/address/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setAddressValue: createAction(
    "vendor/kyc/address/setAddressValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  clearAddress: createAction("vendor/kyc/address/clearAddress"),
  submitAddress: createAction("vendor/kyc/address/submitAddress"),
};
export default vendorAddressActions;

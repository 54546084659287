/* eslint-disable no-shadow */

export enum BusinessStructureType {
  NONE = "none",
  SOLE_PROPRIETORSHIP = "sole_proprietorship",
  LLC = "llc",
  CORPORATION = "corporation",
  PARTNERSHIP = "partnership",
}

export type BusinessInfoStateType = {
  businessName: string;
  doingBusinessAs: string;
  ein: string;
  businessStructure: BusinessStructureType;
};

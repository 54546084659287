import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { AuthStateType } from "@ob/layouts/VendorOnboarding/types/auth";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initAuthState = createAsyncSlice({
  jwt: "",
  loggedIn: false,
  userData: null,
});

const authReducer = createReducer<AuthStateType>(initAuthState, (builder) => {
  builder
    .addCase(va.auth.apiFetching, onApiFetching)
    .addCase(va.auth.apiError, onApiError)
    .addCase(va.auth.apiSuccess, onApiSuccess)
    .addCase(va.auth.setJWT, onSetJWT)
    .addCase(va.auth.setLoggedIn, onSetLoggedIn)
    .addCase(va.auth.setUserData, onSetUserData)
    .addCase(va.auth.setUserStatus, onSetUserStatus);
});
export default authReducer;

export function onApiFetching(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetJWT(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.setJWT>,
) {
  state.data.jwt = action.payload.validJWT;
}

export function onSetLoggedIn(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.setLoggedIn>,
) {
  state.data.loggedIn = action.payload.loggedIn;
}

export function onSetUserData(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.setUserData>,
) {
  state.data.userData = action.payload.userData;
}

export function onSetUserStatus(
  state: AuthStateType,
  action: ReturnType<typeof va.auth.setUserStatus>,
) {
  if (state.data.userData) {
    state.data.userData.status = action.payload.userStatus;
  }
}

import { combineReducers } from "@reduxjs/toolkit";
import { isVendorOnboarding, isTest } from "@ob/utils/appCheck";
import vendorOnboardingReducer from "@ob/layouts/VendorOnboarding/redux/reducers";

export type AppStateType = {
  // Common Reducers
  // Optional Reducers
  vendor?: ReturnType<typeof vendorOnboardingReducer>;
};

export type VendorOnboardingReducersType = {
  vendor: typeof vendorOnboardingReducer;
};

export default function getReducersByApp() {
  const commonReducers = {};

  let appReducers: VendorOnboardingReducersType;
  if (isVendorOnboarding()) {
    appReducers = {
      vendor: vendorOnboardingReducer,
    };
  } else if (isTest()) {
    appReducers = {
      vendor: vendorOnboardingReducer,
    };
  } else {
    throw new Error(`No reducers for app: ${process.env.STITCH_APP}`);
  }
  const reducers = {
    ...commonReducers,
    ...appReducers,
  };
  return combineReducers(reducers);
}

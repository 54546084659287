import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";

import { KyvUserTypes, UpdateInviteResType } from "@ob/api/types";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { selectJWT } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import { selectEmail } from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/email";

export default function* emailSaga() {
  yield takeLatest(va.kyc.email.submitEmail, onSubmitEmail);
}

export function* onSubmitEmail() {
  try {
    const email: string = yield select(selectEmail);
    if (!email) return;
    const jwt: string = yield select(selectJWT);
    yield all([
      put(va.kyc.email.apiFetching(true)),
      put(
        va.kyc.email.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      { email },
      KyvUserTypes.INDIVIDUAL,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyc.email.apiError(res.error)),
          put(va.kyc.email.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.kyc.email.apiError(res.error)),
          put(va.kyc.email.apiFetching(false)),
        ]);
      }
    } else {
      yield delay(400);
      yield all([
        put(va.kyc.email.apiSuccess(true)),
        put(va.kyc.email.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Email. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyc.email.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyc.email.apiFetching(false)),
    ]);
  }
}

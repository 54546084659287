import isDevEnv from "@ob/utils/envCheck";

export function postMsgAcctAdded() {
  if (isDevEnv()) {
    console.log("MOCK POST MSG: account_added"); // eslint-disable-line
  }
  if (window?.postMessage) {
    window.postMessage("account_added", "*");
  }
  if (window?.parent?.postMessage) {
    window.parent.postMessage("account_added", "*");
  }
  if (window?.top?.parent.postMessage) {
    window.top.parent.postMessage("account_added", "*");
  }
  // @ts-expect-error - ReactNativeWebView is not defined
  if (window?.ReactNativeWebView) {
    // @ts-expect-error - ReactNativeWebView is not defined
    window.ReactNativeWebView.postMessage("account_added", "*");
  }
}

export function postMsgUserStatusManualReview() {
  if (isDevEnv()) {
    console.log("MOCK POST MSG: manual_review"); // eslint-disable-line
  }
  if (window?.postMessage) {
    window.postMessage("manual_review", "*");
  }
  if (window?.parent.postMessage) {
    window.parent.postMessage("manual_review", "*");
  }
  if (window?.top?.parent.postMessage) {
    window.top.parent.postMessage("manual_review", "*");
  }
  // @ts-expect-error - ReactNativeWebView is not defined
  if (window?.ReactNativeWebView) {
    // @ts-expect-error - ReactNativeWebView is not defined
    window.ReactNativeWebView.postMessage("manual_review", "*");
  }
}

/**
 * Validate phone number
 * @param value
 * @param cb
 * @returns boolean (true if no error, false if error)
 */
export default function validatePhone(
  value: string,
  cb: (error: string) => void,
): boolean {
  const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  if (!value) {
    cb("Phone number is required");
  } else if (value && !phoneRegex.test(value)) {
    cb("Invalid Phone number, please use the format 123-456-7890");
  } else {
    cb("");
    return true;
  }
  return false;
}

import { BusinessPersonDbType } from "@ob/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

const beneficialOwnersActions = {
  setInfoValue: createAction(
    "vendor/kyb/owners/setInfoValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  setAddressValue: createAction(
    "vendor/kyb/owners/setAddressValue",
    (key: string, value: string) => ({
      payload: { key, value },
    }),
  ),
  setActiveIndex: createAction(
    "vendor/kyb/owners/setActiveIndex",
    (index: number) => ({
      payload: { index },
    }),
  ),
  clearInfo: createAction("vendor/kyb/owners/clearInfo"),
  clearAddress: createAction("vendor/kyb/owners/clearAddress"),
  hydrateOwners: createAction(
    "vendor/kyb/owners/hydrateOwners",
    (owners: BusinessPersonDbType[]) => ({
      payload: { owners },
    }),
  ),
  removeBeneficialOwner: createAction(
    "vendor/kyb/owners/removeBeneficialOwner",
    (index: number) => ({
      payload: { index },
    }),
  ),
  addBeneficialOwner: createAction("vendor/kyb/owners/addBeneficialOwner"),
};
export default beneficialOwnersActions;

// eslint-disable-next-line import/prefer-default-export
export function validateTitle(value: string, cb: (error: string) => void) {
  // NOTE: Allow any alphanumeric, unicode basic Latin characters, unicode Latin extended-A,
  // ampersands (&), apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' ,. -&]+$`);
  if (!value) {
    cb("Title is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid Title");
  } else {
    cb("");
    return true;
  }
  return false;
}

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import StitchLoading from "@ob/components/StitchLoading";
import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectSubmitInviteError,
  selectSubmitInviteFetching,
} from "@ob/layouts/VendorOnboarding/redux/selectors/submit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { isKyvUserTypes, KyvUserTypes } from "@ob/api/types";
import CreateUserApiError from "./components/CreateUserApiError";

export default function SubmitInvite() {
  const params = useParams<{ userType: string }>();
  const paramsUserType: KyvUserTypes = [params.userType].reduce(
    (acc: KyvUserTypes, curr) => (!isKyvUserTypes(curr) ? acc : curr),
    KyvUserTypes.UNKNOWN,
  );
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectSubmitInviteFetching);
  const apiError = useAppSelector(selectSubmitInviteError);

  const [dots, setDots] = useState("");

  useEffect(() => {
    if (!apiFetching) {
      dispatch(va.submit.submitInvite(paramsUserType));
    }
    const intId = setInterval(() => {
      setDots((prev: string) => (prev.length < 3 ? `${prev}.` : ""));
    }, 700);
    return () => {
      dispatch(va.submit.apiSuccess(false));
      dispatch(va.submit.apiError({ status: 0, message: "" }));
      dispatch(va.submit.apiFetching(false));
      clearInterval(intId);
    };
  }, []);

  const handleRetry = () => {
    if (!apiFetching) {
      dispatch(va.submit.submitInvite(paramsUserType));
    }
  };

  if (apiError.status) {
    return <CreateUserApiError apiError={apiError} onRetry={handleRetry} />;
  }

  return (
    <FadeInStack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <StitchLoading />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="baseline"
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" align="center" sx={{ mt: 2 }}>
          Verifying your information
        </Typography>
        <Typography variant="h3" style={{ minWidth: "25px" }}>
          {dots}
        </Typography>
      </Stack>
    </FadeInStack>
  );
}

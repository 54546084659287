import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

export const selectSubmitInviteFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.submit.fetching,
);

export const selectSubmitInviteError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.submit.error,
);

export const selectSubmitInviteSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.submit.success,
);

export const selectManualUpdateInvite = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.submit.data.manualUpdateInvite,
);

export const selectInviteStatus = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.submit.data.status,
);

import { createReducer } from "@reduxjs/toolkit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  BeneficialOwnersStateType,
  isKeyInOwnerInfoType,
  isKeyInOwnerAddressType,
  SingleBeneficialOwnerStateType,
} from "@ob/layouts/VendorOnboarding/types/kyb/owners";

export const initSingleBeneficialOwnerAddressState = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
};

export const initSingleBeneficialOwnerStateType = {
  firstName: "",
  lastName: "",
  email: "",
  ssn: "",
  dob: "",
  title: "",
};

export const initBeneficialOwnersStateType = {
  allOwners: [],
  activeIndex: 0,
};

const adminInfoReducer = createReducer<BeneficialOwnersStateType>(
  initBeneficialOwnersStateType,
  (builder) => {
    builder
      .addCase(va.kyb.owners.hydrateOwners, onHydrateOwnerState)
      .addCase(va.kyb.owners.setInfoValue, onSetInfoValue)
      .addCase(va.kyb.owners.setAddressValue, onSetAddressValue)
      .addCase(va.kyb.owners.setActiveIndex, onSetActiveIndex)
      .addCase(va.kyb.owners.clearInfo, onClearInfo)
      .addCase(va.kyb.owners.clearAddress, onClearAddress)
      .addCase(va.kyb.owners.removeBeneficialOwner, onRemoveBeneficialOwner)
      .addCase(va.kyb.owners.addBeneficialOwner, onAddBeneficialOwner);
  },
);
export default adminInfoReducer;

export function onSetInfoValue(
  state: BeneficialOwnersStateType,
  action: ReturnType<typeof va.kyb.owners.setInfoValue>,
) {
  const { activeIndex } = state;
  const { key, value } = action.payload;
  const activeOwner = state.allOwners[activeIndex];
  if (!activeOwner) {
    const newOwner = {
      info: {
        ...initSingleBeneficialOwnerStateType,
        [key]: value,
      },
      address: { ...initSingleBeneficialOwnerAddressState },
    };
    state.allOwners = [newOwner];
  } else if (
    action.payload.key in activeOwner.info &&
    isKeyInOwnerInfoType(key)
  ) {
    activeOwner.info[key] = value;
    state.allOwners[activeIndex] = activeOwner;
  }
}

export function onSetAddressValue(
  state: BeneficialOwnersStateType,
  action: ReturnType<typeof va.kyb.owners.setAddressValue>,
) {
  const { activeIndex } = state;
  const { key, value } = action.payload;
  const activeOwner = state.allOwners[activeIndex];
  if (!activeOwner) {
    const newOwner = {
      info: { ...initSingleBeneficialOwnerStateType },
      address: {
        ...initSingleBeneficialOwnerAddressState,
        [key]: value,
      },
    };
    state.allOwners = [newOwner];
  } else if (
    action.payload.key in activeOwner.address &&
    isKeyInOwnerAddressType(key)
  ) {
    activeOwner.address[key] = value;
    state.allOwners[activeIndex] = activeOwner;
  }
}

export function onSetActiveIndex(
  state: BeneficialOwnersStateType,
  action: ReturnType<typeof va.kyb.owners.setActiveIndex>,
) {
  state.activeIndex = action.payload.index;
}

export function onClearInfo(state: BeneficialOwnersStateType) {
  const { activeIndex } = state;
  state.allOwners[activeIndex].info = { ...initSingleBeneficialOwnerStateType };
}

export function onClearAddress(state: BeneficialOwnersStateType) {
  const { activeIndex } = state;
  state.allOwners[activeIndex].address = {
    ...initSingleBeneficialOwnerAddressState,
  };
}

export function onHydrateOwnerState(
  state: BeneficialOwnersStateType,
  action: ReturnType<typeof va.kyb.owners.hydrateOwners>,
) {
  const newOwners = action.payload.owners.map((dbOwner) => {
    const stateBeneficialOwner: SingleBeneficialOwnerStateType = {
      ...dbOwner,
      info: {
        ...initSingleBeneficialOwnerStateType,
        firstName: dbOwner.firstName,
        lastName: dbOwner.lastName,
        email: dbOwner.email,
        ssn: "",
        dob: dbOwner.dob,
        title: dbOwner.title ? dbOwner.title : "",
      },
      address: {
        ...initSingleBeneficialOwnerAddressState,
        ...dbOwner.address,
      },
    };
    return stateBeneficialOwner;
  });
  state.allOwners = newOwners;
}

export function onRemoveBeneficialOwner(
  state: BeneficialOwnersStateType,
  action: ReturnType<typeof va.kyb.owners.removeBeneficialOwner>,
) {
  const { index } = action.payload;
  state.allOwners = state.allOwners.filter((_, i) => i !== index);
  if (state.activeIndex === index) {
    state.activeIndex = 0;
  }
}

export function onAddBeneficialOwner(state: BeneficialOwnersStateType) {
  const newOwner = {
    info: { ...initSingleBeneficialOwnerStateType },
    address: { ...initSingleBeneficialOwnerAddressState },
  };
  state.allOwners.push(newOwner);
  if (state.allOwners.length === 1) {
    state.activeIndex = 0;
  } else {
    state.activeIndex = state.allOwners.length - 1;
  }
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";

export interface AsyncStateType {
  success: boolean;
  fetching: boolean;
  error: ErrorPayload;
}

export function isAsyncStateType(value: any): value is AsyncStateType {
  return (
    value &&
    typeof value === "object" &&
    ("fetching" in value ||
      "error" in value ||
      "data" in value ||
      "success" in value)
  );
}
export const createAsyncSlice = <T>(initState: T) => ({
  error: {
    message: "",
    status: 0,
  },
  fetching: false,
  success: false,
  data: initState,
});

import { BankOutlined } from "@ant-design/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import { BankAcctDbType } from "@ob/redux/types/dbTypes";

type Props = {
  bank: BankAcctDbType;
};
export default function BankDetails(props: Props) {
  const theme = useTheme();
  const { bank } = props;
  return (
    <Stack
      key={bank.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        pt: 2,
        minHeight: 5,
        mt: 4,
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ minHeight: "50px" }}>
        <BankOutlined style={{ fontSize: "24px" }} />
        <Stack sx={{ ml: 2 }} alignItems="flex-start">
          <Typography variant="body1">{bank.institutionName}</Typography>
          <Typography variant="body1" color="secondary">
            {bank.name ||
              `${bank.subType[0].toUpperCase()}${bank.subType.slice(
                1,
              )} ${bank.mask.slice(-4)}`}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <div
            style={{
              display: bank.disabled ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            ineligible
          </div>
        </Stack>
        <Stack>
          <div
            style={{
              display: !bank.active ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            inactive
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

import ExpiredTokenApiError from "./expiredTokenError";
import ServerApiError from "./serverApiError";
import UnauthorizedApiError from "./unauthorizedApiError";
import NonCriticalApiError from "./nonCriticalApiError";

export default {
  ExpiredTokenApiError,
  ServerApiError,
  UnauthorizedApiError,
  NonCriticalApiError,
};

export type ApiError =
  | ExpiredTokenApiError
  | ServerApiError
  | NonCriticalApiError
  | UnauthorizedApiError;

import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { PayDestStateType } from "@ob/layouts/VendorOnboarding/types/payDest";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initPayDestState = createAsyncSlice({
  cards: [],
  banks: [],
  userId: "",
  businessProfileId: "",
  userIntentId: "",
  clientId: "",
  sessionToken: "",
});

const payDestReducer = createReducer<PayDestStateType>(
  initPayDestState,
  (builder) => {
    builder
      .addCase(va.payDest.apiFetching, onApiFetching)
      .addCase(va.payDest.apiError, onApiError)
      .addCase(va.payDest.apiSuccess, onApiSuccess)
      .addCase(va.payDest.setCardReqParams, onSetCardReqParams)
      .addCase(va.payDest.clearCardReqParams, onClearCardReqParams)
      .addCase(va.payDest.setCards, onSetCards)
      .addCase(va.payDest.setBankAccts, onSetBankAccts);
  },
);
export default payDestReducer;

export function onApiFetching(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetCardReqParams(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.setCardReqParams>,
) {
  state.data.clientId = action.payload.clientId || "";
  state.data.sessionToken = action.payload.sessionToken;
  state.data.userIntentId = action.payload?.userIntentId || "";
  state.data.userId = action.payload?.userId || "";
  state.data.businessProfileId = action.payload?.businessProfileId || "";
}

export function onClearCardReqParams(state: PayDestStateType) {
  state.data.clientId = "";
  state.data.sessionToken = "";
  state.data.userIntentId = "";
  state.data.userId = "";
  state.data.businessProfileId = "";
}

export function onSetCards(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.setCards>,
) {
  state.data.cards = action.payload.cards;
}

export function onSetBankAccts(
  state: PayDestStateType,
  action: ReturnType<typeof va.payDest.setBankAccts>,
) {
  state.data.banks = action.payload.banks;
}

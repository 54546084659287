export default function formatEin(ein: string) {
  const s = ein.replace(/\D/g, "");
  if (s.length === 2) {
    return `${s}-`;
  }
  if (s.length > 2) {
    return `${s.slice(0, 2)}-${s.slice(2, 9)}`;
  }
  return s;
}

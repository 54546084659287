import type { SagaMiddleware } from "redux-saga";
import { all, spawn } from "redux-saga/effects";
import vendorSaga from "@ob/layouts/VendorOnboarding/redux/sagas";

function getSagasByApp() {
  return [vendorSaga];
}

const sagas = getSagasByApp();

function* rootSaga() {
  yield all(sagas.map((saga) => spawn(saga)));
}

export default function initSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(rootSaga);
}

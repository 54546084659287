import { ChangeEvent, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import { selectAdminInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/info";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import formatBirthday from "@ob/utils/formatBirthday";
import formatSSN from "@ob/utils/formatSSN";
import {
  validateFirstName,
  validateLastName,
  validateSSN,
  validateBirthday,
  validateEmail,
} from "@ob/utils/validation";

export default function AdminInfo() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    ssn: "",
    dob: "",
  });

  const info = useAppSelector(selectAdminInfo);
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleValidation = (name: string, value: string) => {
    switch (name) {
      case "firstName":
        validateFirstName(value, handleValidationResult(name));
        break;
      case "lastName":
        validateLastName(value, handleValidationResult(name));
        break;
      case "email":
        validateEmail(value, handleValidationResult(name));
        break;
      case "ssn":
        validateSSN(value, handleValidationResult(name));
        break;
      case "dob":
        validateBirthday(value, handleValidationResult(name));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const validFirstname = validateFirstName(
      info.firstName,
      handleValidationResult("firstName"),
    );
    const validLastname = validateLastName(
      info.lastName,
      handleValidationResult("lastName"),
    );
    const validEmail = validateEmail(
      info.email,
      handleValidationResult("email"),
    );
    const validSSN = validateSSN(info.ssn, handleValidationResult("ssn"));
    const validDOB = validateBirthday(
      formatBirthday(info.dob),
      handleValidationResult("dob"),
    );
    if (
      !validFirstname ||
      !validLastname ||
      !validEmail ||
      !validSSN ||
      !validDOB
    ) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyb.sync.submitBusiness());
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    handleValidation(name, value);
    switch (name) {
      case "firstName":
        dispatch(va.kyb.admin.info.setFirstName(value));
        break;
      case "lastName":
        dispatch(va.kyb.admin.info.setLastName(value));
        break;
      case "email":
        dispatch(va.kyb.admin.info.setEmail(value));
        break;
      default:
        break;
    }
  };

  const handleOnDobKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "/") {
        nextValue = value.slice(0, -2);
      }
      dispatch(va.kyb.admin.info.setDOB(nextValue));
    }
  };

  const handleOnDobChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s+/g, "");
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }

    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyb.admin.info.setDOB(nextValue));
    } else if (value.length <= 10) {
      dispatch(va.kyb.admin.info.setDOB(value));
    }
  };

  const handleOnSSNKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "-") {
        nextValue = value.slice(0, -2);
      }
      validateSSN(nextValue, handleValidationResult(name));
      dispatch(va.kyb.admin.info.setSSN(nextValue));
    }
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.ssn,
      value.length === 11,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const handleOnSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    validateSSN(value, handleValidationResult(name));
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyb.admin.info.setSSN(nextValue));
    } else if (value.length <= 11) {
      dispatch(va.kyb.admin.info.setSSN(value));
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    let { value } = e.currentTarget;
    if (name === "dob") {
      value = formatBirthday(e.target.value.replace(/\s+/g, ""));
    } else if (name === "ssn") {
      value = formatSSN(value);
    }
    handleValidation(name, value);
    if (
      uiErrors.firstName ||
      uiErrors.lastName ||
      uiErrors.email ||
      uiErrors.ssn ||
      uiErrors.dob
    ) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.firstName,
      !uiErrors.lastName,
      !uiErrors.email,
      !uiErrors.ssn,
      !uiErrors.dob,
      info.firstName,
      info.lastName,
      info.email,
      info.ssn,
      info.dob,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const clearAsyncState = useCallback(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  useEffect(() => {
    if (apiSuccess) {
      clearAsyncState();
      navigate("/kyb/admin_address");
    }
  }, [apiSuccess]);

  useEffect(() => {
    clearAsyncState();
    return () => clearAsyncState();
  }, []);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={36}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Your information</Typography>
        <Typography variant="body1">
          Provide your personal information as it has been affiliated with the
          business.
        </Typography>
      </Stack>
      <Stack
        data-testid="admin-info-input"
        gap={2}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          height: "calc(100vh - 367px)",
          "& .MuiAutocomplete-popper": {
            "::-webkit-scrollbar": {
              width: "12px",
              height: "7px",
            },
            "::-webkit-scrollbar-track": {
              background: "#F1F1F1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#ddd",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#333",
            },
          },
        }}
      >
        <Stack gap={1}>
          <Typography variant="body1">Name</Typography>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="First name"
              name="firstName"
              value={info.firstName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              className="firstName-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.firstName && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.firstName ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.firstName}
            </Typography>
          </Stack>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="Last name"
              name="lastName"
              value={info.lastName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              className="lastName-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.lastName && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.lastName ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.lastName}
            </Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">Email</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Email"
            name="email"
            value={info.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="email-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.email ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.email}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">SSN</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="###-##-####"
            name="ssn"
            value={formatSSN(info.ssn)}
            onChange={handleOnSSNChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnSSNKeyDown}
            className="ssn-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.ssn ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.ssn}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">DOB</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="MM / DD / YYYY"
            name="dob"
            value={formatBirthday(info.dob)}
            onChange={handleOnDobChange}
            onKeyDown={handleOnDobKeyDown}
            onBlur={handleOnBlur}
            className="birthday-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.dob ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.dob}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1} sx={{ height: "100px" }}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleSubmit}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import authActions from "./auth";
import configActions from "./config";
import phoneActions from "./phone";
import fullNameActions from "./kyc/fullName";
import dobActions from "./kyc/dob";
import ssnActions from "./kyc/ssn";
import addressActions from "./kyc/address";
import emailActions from "./kyc/email";
import submitActions from "./submit";
import routeActions from "./routes";
import payDestActions from "./payDest";
import businessSyncActions from "./kyb/sync";
import businessInfoActions from "./kyb/business/info";
import businessAddressActions from "./kyb/business/address";
import adminInfoActions from "./kyb/admin/info";
import adminAddressActions from "./kyb/admin/address";
import businessControllerActions from "./kyb/controller/info";
import businessControllerAddressActions from "./kyb/controller/address";
import beneficialOwnersActions from "./kyb/owners";

const vendorActions = {
  auth: authActions,
  config: configActions,
  payDest: payDestActions,
  routes: routeActions,
  phone: phoneActions,
  kyc: {
    fullName: fullNameActions,
    dob: dobActions,
    ssn: ssnActions,
    address: addressActions,
    email: emailActions,
  },
  kyb: {
    sync: businessSyncActions,
    business: {
      info: businessInfoActions,
      address: businessAddressActions,
    },
    admin: {
      info: adminInfoActions,
      address: adminAddressActions,
    },
    controller: {
      info: businessControllerActions,
      address: businessControllerAddressActions,
    },
    owners: beneficialOwnersActions,
  },
  submit: submitActions,
};

export default vendorActions;

import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { BusinessSyncStateType } from "@ob/layouts/VendorOnboarding/types/kyb/sync";

export const initBusinessSyncState = createAsyncSlice({});

const businessSyncReducer = createReducer<BusinessSyncStateType>(
  initBusinessSyncState,
  (builder) => {
    builder
      .addCase(va.kyb.sync.apiFetching, onApiFetching)
      .addCase(va.kyb.sync.apiError, onApiError)
      .addCase(va.kyb.sync.apiSuccess, onApiSuccess);
  },
);
export default businessSyncReducer;

export function onApiFetching(
  state: BusinessSyncStateType,
  action: ReturnType<typeof va.kyb.sync.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: BusinessSyncStateType,
  action: ReturnType<typeof va.kyb.sync.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: BusinessSyncStateType,
  action: ReturnType<typeof va.kyb.sync.apiSuccess>,
) {
  state.success = action.payload.success;
}

import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { UserDBType, UserStatusType } from "@ob/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

const vendorAuthActions = {
  apiFetching: createAction("vendor/auth/apiFetching", (fetching: boolean) => ({
    payload: { fetching },
  })),
  apiError: createAction("vendor/auth/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/auth/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  setJWT: createAction("vendor/auth/setJWT", (validJWT: string) => ({
    payload: { validJWT },
  })),
  setExtraParams: createAction(
    "vendor/auth/setExtraParams",
    (extraParams: string) => ({
      payload: { extraParams },
    }),
  ),
  setLoggedIn: createAction("vendor/auth/setLoggedIn", (loggedIn: boolean) => ({
    payload: { loggedIn },
  })),
  getUserData: createAction(
    "vendor/auth/getUserData",
    (jwt: string, options: { redirect: string }) => ({
      payload: { jwt, options },
    }),
  ),
  setUserData: createAction(
    "vendor/auth/setUserData",
    (userData: UserDBType) => ({
      payload: { userData },
    }),
  ),
  setUserStatus: createAction(
    "vendor/auth/setUserStatus",
    (userStatus: UserStatusType) => ({
      payload: { userStatus },
    }),
  ),
  sendReloginLink: createAction("vendor/auth/sendReloginLink"),
};

export default vendorAuthActions;

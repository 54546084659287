import { createAction } from "@reduxjs/toolkit";

const businessControllerActions = {
  setTitle: createAction(
    "vendor/kyb/controller/info/setTitle",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setFirstName: createAction(
    "vendor/kyb/controller/info/setFirstName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setLastName: createAction(
    "vendor/kyb/controller/info/setLastName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setEmail: createAction(
    "vendor/kyb/controller/info/setEmail",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setDOB: createAction(
    "vendor/kyb/controller/info/setDOB",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setSSN: createAction(
    "vendor/kyb/controller/info/setSSN",
    (value: string) => ({
      payload: { value },
    }),
  ),
  ssnApiSuccess: createAction(
    "vendor/kyb/controller/info/ssnApiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
};

export default businessControllerActions;

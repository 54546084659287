import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectFirstName,
  selectFullNameError,
  selectFullNameFetching,
  selectFullNameSuccess,
  selectLastName,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/fullName";
import { validateFirstName, validateLastName } from "@ob/utils/validation";

export default function FullName() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    firstName: "",
    lastName: "",
  });

  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const apiFetching = useAppSelector(selectFullNameFetching);
  const apiError = useAppSelector(selectFullNameError);
  const apiSuccess = useAppSelector(selectFullNameSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setShowErrors(false);
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleValidation = (name: string, value: string) => {
    switch (name) {
      case "firstName":
        validateFirstName(value, handleValidationResult(name));
        break;
      case "lastName":
        validateLastName(value, handleValidationResult(name));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const validFirstname = validateFirstName(
      firstName,
      handleValidationResult("firstName"),
    );
    const validLastname = validateLastName(
      lastName,
      handleValidationResult("lastName"),
    );
    if (!validFirstname || !validLastname) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyc.fullName.submitName());
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    handleValidation(name, value);
    dispatch(va.kyc.fullName.setNameValue(name, value));
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    handleValidation(name, value);
    if (uiErrors.firstName || uiErrors.lastName) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.firstName,
      !uiErrors.lastName,
      firstName,
      lastName,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  useEffect(
    () =>
      function unmount() {
        dispatch(va.kyc.fullName.apiSuccess(false));
        dispatch(va.kyc.fullName.apiFetching(false));
        dispatch(
          va.kyc.fullName.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  useEffect(() => {
    if (apiSuccess) {
      navigate("/kyc/birthday");
    }
  }, [apiSuccess]);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Your legal name</Typography>
        <Typography variant="body1">
          Enter your first and last name as they appear on a government ID.
        </Typography>
      </Stack>
      <Stack data-testid="phone-input" gap={1}>
        <Stack>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="First name"
            name="firstName"
            value={firstName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="fullName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.firstName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.firstName}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Last name"
            name="lastName"
            value={lastName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="fullName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.lastName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.lastName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.lastName}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleSubmit}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

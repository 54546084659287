export default function formatPhoneNumber(_phoneNumber: string = ""): string {
  let pn = _phoneNumber.replace(/(-)+/g, "");
  if (pn.slice(0, 2) === "+1") {
    pn = pn.slice(2);
  } else if (pn[0] === "1" && pn.length === 11) {
    pn = pn.slice(1);
  }
  if (pn.length < 3) {
    return pn;
  }
  if (pn.length < 6) {
    return `${pn.slice(0, 3)}-${pn.slice(3)}`;
  }
  if (pn.length <= 10) {
    return `${pn.slice(0, 3)}-${pn.slice(3, 6)}-${pn.slice(6)}`;
  }
  if (pn.length > 10) {
    return `${pn.slice(0, 3)}-${pn.slice(3, 6)}-${pn.slice(6, 10)}`;
  }
  return pn;
}

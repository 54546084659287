import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { CreditCardDbType, BankAcctDbType } from "@ob/redux/types/dbTypes";

export const selectPayDestFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.payDest.fetching,
);

export const selectPayDestError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.payDest.error,
);

export const selectPayDestSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.payDest.success,
);

export const selectClientId = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.payDest.data.clientId,
);

export const selectSessionToken = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.payDest.data.sessionToken,
);

export const selectUserIntentId = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.payDest.data.userIntentId || "",
);

export const selectUserId = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.payDest.data.userId || "",
);

export const selectBusinessProfileId = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.payDest.data.businessProfileId || "",
);

export const selectAllCards = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): CreditCardDbType[] => vendor.payDest.data.cards || [],
);

export const selectAllBanks = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): BankAcctDbType[] => vendor.payDest.data.banks || [],
);

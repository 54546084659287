import { LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FadeInStack } from "@ob/components/FadeComponents";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function BusinessControllerCheck() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);

  const handleBusinessControllerAffirmative = () => {
    setIsLoading(true);
    navigate("/kyb/business_controller_title");
  };

  const handleBusinessControllerNegative = () => {
    setIsLoading(true);
    navigate("/kyb/business_controller_register");
  };

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={72}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Business Controller</Typography>
        <Typography variant="body1">
          Are you a business representative with significant management
          responsibilities and the authority to make decisions within the
          business?
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Typography variant="body1" color="gray">
          Who is a business controller?
        </Typography>
        <Typography variant="body1" color="gray">
          Senior officers, individuals who can appoint or remove senior
          officers, recognized decision-makers, and/or anyone who exercises
          other forms of substantial control over the company. If you are not
          sure if this applies to you, select &quot;No.&quot;
        </Typography>
      </Stack>
      <Stack gap={1}>
        <StitchAsyncButton
          buttonText="Yes"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleBusinessControllerAffirmative}
          loading={loading}
          success={loading}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
        <StitchAsyncButton
          buttonText="No"
          variant="outlined"
          color="primary"
          logoColor="white"
          onClick={handleBusinessControllerNegative}
          loading={loading}
          success={loading}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

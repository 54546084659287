import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

export const selectPhoneNumber = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): string => state.vendor.phone.data.phoneNumber || "",
);

export const selectOTPValid = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): boolean => state.vendor.phone.data.otpValid || false,
);

export const selectPhoneCode = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): string => state.vendor.phone.data.phoneCode || "",
);

export const selectPhoneFetching = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): boolean => state.vendor.phone.fetching || false,
);

export const selectPhoneError = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): ErrorPayload =>
    state.vendor.phone.error || { message: "", status: 0 },
);

export const selectPhoneSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state,
  (state): boolean => state.vendor.phone.success || false,
);

import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { SingleBeneficialOwnerStateType } from "@ob/layouts/VendorOnboarding/types/kyb/owners";
import BusinessPersonRow from "../BusinessPersonRow";
import BusinessOwnerAddRow from "./components/BusinessOwnerAddRow";
import AddBeneficialOwner from "./components/AddBeneficialOwner";

type Props = {
  owners: SingleBeneficialOwnerStateType[];
  onAdd: () => void;
  onEdit: (index: number) => void;
};
export default function BeneficialOwnersGroup(props: Props) {
  return (
    <Stack>
      <Typography sx={{ fontFamily: "CircularBold" }}>
        Beneficial owner{props.owners.length > 1 ? "s" : ""}
      </Typography>
      {props.owners.map((owner, i) => (
        <Stack key={owner.id || owner.info.firstName}>
          <BusinessPersonRow
            firstName={owner.info.firstName}
            lastName={owner.info.lastName}
            title={owner.info.title}
            street1={owner.address.street1}
            street2={owner.address.street2}
            city={owner.address.city}
            state={owner.address.state}
            zip={owner.address.zip}
            onEditRow={() => props.onEdit(i)}
          />
          <Divider sx={{ my: 1 }} color="secondary" />
        </Stack>
      ))}
      {(() => {
        if (props.owners.length === 0)
          return <BusinessOwnerAddRow onAdd={props.onAdd} />;
        if (props.owners.length < 4)
          return <AddBeneficialOwner onAdd={props.onAdd} />;
        return null;
      })()}
    </Stack>
  );
}

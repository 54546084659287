import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorKycSsnActions = {
  apiFetching: createAction(
    "vendor/kyc/ssn/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/kyc/ssn/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/kyc/ssn/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  setSSN: createAction("vendor/kyc/ssn/setSSN", (ssn: string) => ({
    payload: { ssn },
  })),
  clearSSN: createAction("vendor/kyc/ssn/clearSSN"),
  submitSSN: createAction("vendor/kyc/ssn/submitSSN"),
};
export default vendorKycSsnActions;

export default function formatSSN(ssn: string) {
  const match = ssn.match(/^(\d{3})-(\d{2})-(\d{4})$/);
  if (match) {
    return match[0];
  }
  if (ssn.length > 11) {
    return ssn.slice(0, 11);
  }

  const s = ssn.replace(/-/g, "");
  if (s.length === 3) {
    return `${s}-`;
  }
  if (s.length > 3 && s.length < 5) {
    return `${s.slice(0, 3)}-${s.slice(3)}`;
  }
  if (s.length >= 5) {
    return `${s.slice(0, 3)}-${s.slice(3, 5)}-${s.slice(5)}`;
  }
  return s;
}

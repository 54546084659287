import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { SSNStateType } from "@ob/layouts/VendorOnboarding/types/kyc/ssn";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initSSNState = createAsyncSlice("");

const ssnReducer = createReducer<SSNStateType>(initSSNState, (builder) => {
  builder
    .addCase(va.kyc.ssn.apiFetching, onApiFetching)
    .addCase(va.kyc.ssn.apiError, onApiError)
    .addCase(va.kyc.ssn.apiSuccess, onApiSuccess)
    .addCase(va.kyc.ssn.setSSN, onSetSSN)
    .addCase(va.kyc.ssn.clearSSN, onClearSSN);
});
export default ssnReducer;

export function onApiFetching(
  state: SSNStateType,
  action: ReturnType<typeof va.kyc.ssn.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: SSNStateType,
  action: ReturnType<typeof va.kyc.ssn.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: SSNStateType,
  action: ReturnType<typeof va.kyc.ssn.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetSSN(
  state: SSNStateType,
  action: ReturnType<typeof va.kyc.ssn.setSSN>,
) {
  state.data = action.payload.ssn;
}

export function onClearSSN(state: SSNStateType) {
  state.data = "";
}

import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

export const selectSSN = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.ssn.data,
);

export const selectSSNFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.ssn.fetching,
);

export const selectSSNError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.ssn.error,
);

export const selectSSNSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.ssn.success,
);

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { Stack, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectEmail,
  selectEmailError,
  selectEmailFetching,
  selectEmailSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/email";
import { validateEmail } from "@ob/utils/validation";

export default function Email() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    email: "",
  });

  const email = useAppSelector(selectEmail);
  const apiFetching = useAppSelector(selectEmailFetching);
  const apiError = useAppSelector(selectEmailError);
  const apiSuccess = useAppSelector(selectEmailSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const validEmail = validateEmail(email, handleValidationResult("email"));
    if (!validEmail) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyc.email.submitEmail());
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    validateEmail(value, handleValidationResult(name));
    dispatch(va.kyc.email.setEmail(value));
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    validateEmail(value, handleValidationResult(name));
    setShowErrors(true);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      !uiErrors.email,
      email,
      !apiFetching,
      e.key === "Enter",
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (apiSuccess) {
      navigate("/submit/individual");
    }
  }, [apiSuccess]);

  useEffect(
    () =>
      function unmount() {
        dispatch(va.kyc.email.apiSuccess(false));
        dispatch(va.kyc.email.apiFetching(false));
        dispatch(
          va.kyc.email.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Your email</Typography>
        <Typography variant="body1">
          Enter an email where we can send you payment confirmation details.
        </Typography>
      </Stack>
      <Stack data-testid="phone-input" gap={2}>
        <Stack sx={{ minHeight: "75px" }}>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Email address"
            name="email"
            value={email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, email: "" }))}
            onKeyDown={handleOnKeyDown}
            className="email-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.email && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.email ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.email}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Submit"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

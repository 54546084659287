import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { AdminInfoStateType } from "@ob/layouts/VendorOnboarding/types/kyb/admin/info";
import { createSelector } from "@reduxjs/toolkit";

export const selectFirstName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.firstName,
);

export const selectLastName = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.lastName,
);

export const selectEmail = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.email,
);

export const selectSSN = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.ssn,
);

export const selectDOB = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.dob,
);

export const selectTitle = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.info.title,
);

export const selectAdminInfo = createSelector(
  selectFirstName,
  selectLastName,
  selectEmail,
  selectSSN,
  selectDOB,
  selectTitle,
  (firstName, lastName, email, ssn, dob, title): AdminInfoStateType => ({
    firstName,
    lastName,
    email,
    ssn,
    dob,
    title,
  }),
);

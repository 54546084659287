import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { AddressStateType } from "@ob/layouts/VendorOnboarding/types/address";

export const selectStreet1 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.controller.address.street1,
);

export const selectStreet2 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.controller.address.street2,
);

export const selectCity = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.controller.address.city,
);

export const selectState = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.controller.address.state,
);

export const selectZip = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.controller.address.zip,
);

export const selectControllerAddressComplete = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => {
    const { address } = vendor.kyb.controller;
    return [address.street1, address.city, address.state, address.zip].every(
      Boolean,
    );
  },
);

export const selectControllerAddress = createSelector(
  selectStreet1,
  selectStreet2,
  selectCity,
  selectState,
  selectZip,
  (street1, street2, city, state, zip): AddressStateType => ({
    street1,
    street2,
    city,
    state,
    zip,
  }),
);

type AnyObject = {
  [key: string]: any;
};

export default function transformSnakeToCamel(obj: any): any {
  const toCamelCase = (str: string): string =>
    str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );

  const transformKeys = (current: any): any => {
    if (Array.isArray(current)) {
      return current.map(transformKeys);
    }
    if (current !== null && typeof current === "object") {
      /* eslint-disable no-param-reassign */
      return Object.keys(current).reduce<AnyObject>((acc, key) => {
        const transformed: string = toCamelCase(key);
        acc[transformed] = transformKeys(current[key]);
        return acc;
      }, {});
    }
    return current;
  };

  return transformKeys(obj);
}

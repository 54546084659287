import { LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FadeInStack } from "@ob/components/FadeComponents";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import selectBeneficialOwners from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/owner";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { selectAdminInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/info";
import { selectAdminAddress } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/address";
import { AdminInfoStateType } from "@ob/layouts/VendorOnboarding/types/kyb/admin/info";
import { AddressDbType } from "@ob/redux/types/dbTypes";

export default function AdminBeneficialOwnerCheck() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const allOwners = useAppSelector(selectBeneficialOwners);
  const adminInfo = useAppSelector(selectAdminInfo);
  const adminAddress = useAppSelector(selectAdminAddress);

  const copyAdminToBeneficialOwners = useCallback(
    (info: AdminInfoStateType, address: AddressDbType) => {
      if (allOwners.length === 0) {
        dispatch(va.kyb.owners.addBeneficialOwner());
        dispatch(va.kyb.owners.setInfoValue("firstName", info.firstName));
        dispatch(va.kyb.owners.setInfoValue("lastName", info.lastName));
        dispatch(va.kyb.owners.setInfoValue("email", info.email));
        dispatch(va.kyb.owners.setInfoValue("dob", info.dob));
        dispatch(va.kyb.owners.setInfoValue("ssn", info.ssn));
        dispatch(va.kyb.owners.setInfoValue("title", info.title));
        dispatch(va.kyb.owners.setAddressValue("street1", address.street1));
        dispatch(va.kyb.owners.setAddressValue("street2", address.street2));
        dispatch(va.kyb.owners.setAddressValue("city", address.city));
        dispatch(va.kyb.owners.setAddressValue("state", address.state));
        dispatch(va.kyb.owners.setAddressValue("zip", address.zip));
      }
    },
    [],
  );

  const handleBeneficialOwnerAffirmative = () => {
    setIsLoading(true);
    copyAdminToBeneficialOwners(adminInfo, adminAddress);
    navigate("/kyb/business_controller_check");
  };

  const handleBeneficialOwnerNegative = () => {
    setIsLoading(true);
    navigate("/kyb/business_controller_check");
  };

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={60}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Beneficial Owner</Typography>
        <Typography variant="body1">
          Are you a business representative who owns &ge;25% of the business?
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Typography variant="body1" color="gray">
          Who is a beneficial owner?
        </Typography>
        <Typography variant="body1" color="gray">
          Business owners or beneficial owners have at least 25% of the
          company&apos;s ownership interests and may or may not have substantial
          control over a company. If you are not sure, select &quot;No.&quot;
        </Typography>
      </Stack>
      <Stack gap={1}>
        <StitchAsyncButton
          buttonText="Yes"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleBeneficialOwnerAffirmative}
          loading={loading}
          success={loading}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
        <StitchAsyncButton
          buttonText="No"
          variant="outlined"
          color="primary"
          logoColor="white"
          onClick={handleBeneficialOwnerNegative}
          loading={loading}
          success={loading}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

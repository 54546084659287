export function validateAccount(value: string, cb: (error: string) => void) {
  const acctChars = /^[0-9]+$/;
  if (!value) {
    cb("Account Number is required");
  } else if (value && value.length < 5) {
    cb("Account Number must be at least 5 characters");
  } else if (value && value.length > 17) {
    cb("Account Number must be less than 17 characters");
  } else if (value && !acctChars.test(value)) {
    cb("Invalid Account Number. Please use numbers only.");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateRouting(value: string, cb: (error: string) => void) {
  const routingChars = /^[0-9]+$/;
  if (!value) {
    cb("Routing Number is required");
  } else if (value && value.length < 9) {
    cb("Routing Number must be exactly 9 digits.");
  } else if (value && value.length > 9) {
    cb("Routing Number must be exactly 9 digits.");
  } else if (value && !routingChars.test(value)) {
    cb("Invalid Routing Number. Please use numbers only.");
  } else {
    cb("");
    return true;
  }
  return false;
}

export function validateAccountType(
  value: string | undefined,
  cb: (error: string) => void,
) {
  const acctTypes = /^(savings|checking)$/i;
  if (!value) {
    cb("Account Type is required");
  } else if (value && !acctTypes.test(value)) {
    cb("Invalid Account Type. Please use 'savings' or 'checking'.");
  } else {
    cb("");
    return true;
  }
  return false;
}

import { Typography, ButtonBase } from "@mui/material";
import { Stack } from "@mui/system";
import { PlusCircleOutlined, RightOutlined } from "@ant-design/icons";

export default function AddBeneficialOwner(props: { onAdd: () => void }) {
  return (
    <ButtonBase sx={{ width: "100%" }} onClick={props.onAdd}>
      <Stack
        direction="row"
        sx={{ width: "100%", mt: 2 }}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <PlusCircleOutlined style={{ fontSize: "22px" }} />
          <Typography variant="body1"> Add beneficial owner</Typography>
        </Stack>
        <RightOutlined style={{ fontSize: "12px" }} />
      </Stack>
    </ButtonBase>
  );
}

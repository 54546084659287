import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { FullNameStateType } from "@ob/layouts/VendorOnboarding/types/kyc/fullName";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initFullNameState = createAsyncSlice({
  firstName: "",
  lastName: "",
});

const fullNameReducer = createReducer<FullNameStateType>(
  initFullNameState,
  (builder) => {
    builder
      .addCase(va.kyc.fullName.apiFetching, onApiFetching)
      .addCase(va.kyc.fullName.apiError, onApiError)
      .addCase(va.kyc.fullName.apiSuccess, onApiSuccess)
      .addCase(va.kyc.fullName.setNameValue, onSetNameValue);
  },
);
export default fullNameReducer;

export function onApiFetching(
  state: FullNameStateType,
  action: ReturnType<typeof va.kyc.fullName.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: FullNameStateType,
  action: ReturnType<typeof va.kyc.fullName.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: FullNameStateType,
  action: ReturnType<typeof va.kyc.fullName.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetNameValue(
  state: FullNameStateType,
  action: ReturnType<typeof va.kyc.fullName.setNameValue>,
) {
  if (action.payload.key in state.data) {
    state.data = {
      ...state.data,
      [action.payload.key]: action.payload.value,
    };
  }
}

export function onClearName(state: FullNameStateType) {
  state.data.firstName = "";
  state.data.lastName = "";
  state.fetching = false;
  state.error = {
    message: "",
    status: 0,
  };
}

import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import { createAction } from "@reduxjs/toolkit";

const businessInfoActions = {
  setBusinessName: createAction(
    "vendor/kyb/business/info/setBusinessName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  clearBusinessName: createAction("vendor/kyb/business/info/clearBusinessName"),
  setEin: createAction("vendor/kyb/business/info/setEin", (value: string) => ({
    payload: { value },
  })),
  clearEin: createAction("vendor/kyb/business/info/clearEin"),
  setDoingBusinessAs: createAction(
    "vendor/kyb/business/info/setDoingBusinessAs",
    (value: string) => ({
      payload: { value },
    }),
  ),
  clearDoingBusinessAs: createAction(
    "vendor/kyb/business/info/clearDoingBusinessAs",
  ),
  setBusinessStructure: createAction(
    "vendor/kyb/business/info/setBusinessStructure",
    (value: BusinessStructureType) => ({
      payload: { value },
    }),
  ),
  clearBusinessStructure: createAction(
    "vendor/kyb/business/info/clearBusinessStructure",
  ),
};
export default businessInfoActions;

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { Stack, Typography, useTheme } from "@mui/material";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectPhoneError,
  selectPhoneFetching,
  selectOTPValid,
  selectPhoneCode,
  selectPhoneNumber,
  selectPhoneSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/phone";
import "./style.css";
import {
  selectConfigApiSuccess,
  selectKybConfigEnabled,
} from "@ob/layouts/VendorOnboarding/redux/selectors/config";

export default function PhoneCode() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectPhoneFetching);
  const apiError = useAppSelector(selectPhoneError);
  const apiSuccess = useAppSelector(selectPhoneSuccess);
  const configApiSuccess = useAppSelector(selectConfigApiSuccess);
  const kybConfigEnabled = useAppSelector(selectKybConfigEnabled);
  const otpValid = useAppSelector(selectOTPValid);
  const phoneCode = useAppSelector(selectPhoneCode);
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const [resendFetching, setResendFetching] = useState(false);
  const [showResentMsg, setShowResentMsg] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOnChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.currentTarget;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    if (index === 5 && phoneCode.length === 6) {
      return;
    }
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.phone.setPhoneValue(name, nextValue));
    } else dispatch(va.phone.setPhoneValue(name, value));

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    if (value.length > 1) {
      dispatch(va.phone.setPhoneValue("phoneCode", value));
      value.split("").forEach((val, i) => {
        if (i < 5) {
          inputRefs.current[index + i + 1]?.focus();
        }
      });
    }
  };

  const handleSubmit = () => {
    if (!(phoneCode.length === 6)) {
      return;
    }
    setSubmitFetching(true);
    setShowResentMsg(false);
    dispatch(va.phone.submitPhoneCode());
  };

  const handleOnKeyDown = (
    _index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { name } = e.currentTarget;
    const { key } = e;
    const codeInputs: NodeListOf<HTMLInputElement> =
      document.querySelectorAll(".phoneNumber-input");
    if (key === "Backspace") {
      const { value } = codeInputs[_index];

      if (value) {
        dispatch(va.phone.setPhoneValue(name, ""));
      } else {
        const prevIndex = _index - 1;
        const prevValue = codeInputs[prevIndex].value;
        if (prevValue) {
          dispatch(va.phone.setPhoneValue(`phoneCode.${prevIndex}`, ""));
          codeInputs[prevIndex].focus();
        }
      }
    } else if (key === "Enter") {
      handleSubmit();
    }
  };

  const handleResendCode = () => {
    dispatch(va.phone.setPhoneValue("phoneCode", ""));
    setResendFetching(true);
    dispatch(va.phone.resendPhoneCode());
  };

  useEffect(() => {
    if (resendFetching && !apiFetching) {
      setResendFetching(false);
      setShowResentMsg(true);
    } else if (submitFetching && !apiFetching) {
      setSubmitFetching(false);
      setSubmitSuccess(true);
    }
  }, [apiFetching, resendFetching, submitFetching]);

  useEffect(() => {
    const useKyb = configApiSuccess && kybConfigEnabled;
    if (otpValid && apiSuccess) {
      if (configApiSuccess) {
        if (useKyb) {
          navigate("/get_started");
        } else {
          navigate("/kyc/name");
        }
      } else {
        dispatch(va.config.getConfig());
      }
    }
  }, [otpValid, apiSuccess, configApiSuccess, kybConfigEnabled]);

  useEffect(() => {
    if (phoneCode.length === 6) {
      handleSubmit();
    }
  }, [phoneCode]);

  useEffect(() => {
    dispatch(va.phone.clearPhoneCode());
    return () => {
      dispatch(va.phone.apiSuccess(false));
      dispatch(va.phone.apiFetching(false));
      dispatch(
        va.phone.apiError({
          status: 0,
          message: "",
        }),
      );
    };
  }, []);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start">
        <Typography variant="h2">Phone number</Typography>
        <Typography variant="body1">
          Enter the Stitch verification code sent to your number.
        </Typography>
      </Stack>
      <Stack
        data-testid="phone-input"
        direction="row"
        justifyContent="center"
        gap={1}
      >
        {[1, 2, 3, 4, 5, 6].map((v, index) => (
          <input
            key={v}
            disabled={apiFetching}
            type="number"
            inputMode="numeric"
            pattern="[0-9]{1}"
            name={`phoneCode.${index}`}
            value={!phoneCode[index] ? "" : phoneCode[index]}
            ref={(el) => {
              inputRefs.current[index] = el;
            }}
            onChange={(e) => handleOnChange(index, e)}
            onKeyDown={(e) => handleOnKeyDown(index, e)}
            className="phoneNumber-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              width: "40px",
              fontSize: "24px",
              lineHeight: "16px",
              fontFamily: "CircularRegular",
              textAlign: "center",
            }}
          />
        ))}
      </Stack>
      <Stack gap={2}>
        <Typography
          sx={{
            display: showResentMsg ? "block" : "none",
          }}
          variant="subtitle1"
          color={theme.palette.success.darker}
          align="center"
        >
          Code resent! Check your messages.
        </Typography>
        <LocalErrorMsg error={apiError} />
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          If you did not receive a code, resend a code to{" "}
          {phoneNumber
            .replace("+", "")
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4")}{" "}
          or try another number.
        </Typography>
        <StitchAsyncButton
          buttonText="Resend code"
          variant="outlined"
          color="primary"
          logoColor="black"
          onClick={() => handleResendCode()}
          success={showResentMsg}
          loading={resendFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={submitSuccess}
          loading={submitFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}

import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";

export const selectDob = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.dob.data,
);

export const selectDobFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.dob.fetching,
);

export const selectDobError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.dob.error,
);

export const selectDobSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.dob.success,
);

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Stack, ButtonBase, useTheme } from "@mui/material";
import { useAppSelector } from "@ob/redux/store";
import { useLocation, useNavigate, useParams } from "react-router";
import { selectKybConfigEnabled } from "@ob/layouts/VendorOnboarding/redux/selectors/config";
import { selectControllerInfoComplete } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/info";
import { selectControllerAddressComplete } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/address";

type RouteMap = {
  [key: string]: string;
};

const backRouteMap: RouteMap = {
  // Universal
  "/phone_verification": "/phone",
  "/credit_card": "/kyc/email",
  "/bank_account": "/linked_accounts",
  // KYC
  "/kyc/name": "get_started",
  "/kyc/birthday": "/kyc/name",
  "/kyc/ssn": "/kyc/birthday",
  "/kyc/address": "/kyc/ssn",
  "/kyc/email": "/kyc/address",
  // KYB
  "/kyb/admin_info": "/kyb/business_address",
  "/kyb/admin_address": "/kyb/admin_info",
  "/kyb/business_info": "/get_started",
  "/kyb/business_address": "/kyb/business_info",
  "/kyb/admin_beneficial_owner_check": "/kyb/admin_address",
  "/kyb/business_controller_check": "/kyb/admin_beneficial_owner_check",
  "/kyb/business_controller_title": "/kyb/business_controller_check",
  "/kyb/business_controller_register": "/kyb/business_controller_check",
  "/kyb/business_controller_info": "/kyb/business_controller_register",
  "/kyb/owners_info": "/kyb/review",
  "/kyb/owners_address": "/kyb/owners_info",
};

export default function Navbar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams<{ [key: string]: string }>();
  const activeRoute: { pathname: string } = useLocation();
  const kybConfigEnabled = useAppSelector(selectKybConfigEnabled);
  const kybControllerInfoComplete = useAppSelector(
    selectControllerInfoComplete,
  );
  const kybControllerAddressComplete = useAppSelector(
    selectControllerAddressComplete,
  );

  if (kybControllerAddressComplete && kybControllerInfoComplete) {
    backRouteMap["/kyb/business_controller_info"] = "/kyb/review";
    backRouteMap["/kyb/admin_info"] = "/kyb/review";
  }

  const handleBack = () => {
    let nextRoute = "";
    if (Object.entries(params).length > 0) {
      try {
        const [param] = Object.keys(params);
        const lookupRoute = activeRoute.pathname.replace(
          `/${params[param]}`,
          "",
        );
        nextRoute = backRouteMap[lookupRoute];
        if (!nextRoute.includes("/review")) {
          nextRoute += `/${params[param]}`;
        }
      } catch (e) {
        console.error("Error parsing params", e);
      }
    } else {
      nextRoute = backRouteMap[activeRoute.pathname];
    }
    if (!nextRoute) {
      console.error("No route found for ", activeRoute.pathname);
      return;
    }
    navigate(nextRoute);
  };

  const emptyNavbar = (
    <Stack
      alignItems="flex-start"
      justifyContent="center"
      sx={{
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        p: "16px 24px",
        width: "100%",
        height: "50px",
      }}
    />
  );

  if (
    [
      "/submit/individual",
      "/submit/business",
      "/submit/undefined",
      "/success",
    ].includes(activeRoute.pathname)
  ) {
    return null;
  }

  if (
    [
      "/authorize",
      "/phone",
      "/manual_review",
      "/session_expired",
      "/submit/individual",
      "/submit/business",
      "/submit/undefined",
      "/kyb/review",
      "/get_started",
      "/credit_card",
      "/delete_success",
      "/success",
      "/astra_redirect",
      "/linked_accounts",
      "/redirect_landing",
      "/relink_account",
    ].includes(activeRoute.pathname)
  ) {
    return emptyNavbar;
  }
  if (activeRoute.pathname === "/kyc/name" && !kybConfigEnabled) {
    return emptyNavbar;
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        p: "16px 24px",
        width: "100%",
        maxHeight: "50px",
      }}
    >
      <Stack alignItems="flex-start" sx={{ width: "750px" }}>
        <ButtonBase
          onClick={() => handleBack()}
          sx={{
            p: "8px",
            height: "40px",
            width: "40px",
            ":focus": {
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "24px" }} />
        </ButtonBase>
      </Stack>
    </Stack>
  );
}

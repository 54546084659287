export const extraLoadParams = [
  "email",
  "phone",
  "first_name",
  "last_name",
  "dob",
  "address_line1",
  "address_line2",
  "address_city",
  "address_state",
  "address_postal_code",
];

import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { EmailStateType } from "@ob/layouts/VendorOnboarding/types/kyc/email";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initEmailState = createAsyncSlice("");

const emailReducer = createReducer<EmailStateType>(
  initEmailState,
  (builder) => {
    builder
      .addCase(va.kyc.email.apiFetching, onApiFetching)
      .addCase(va.kyc.email.apiError, onApiError)
      .addCase(va.kyc.email.apiSuccess, onApiSuccess)
      .addCase(va.kyc.email.setEmail, onSetEmail)
      .addCase(va.kyc.email.clearEmail, onClearEmail);
  },
);
export default emailReducer;

export function onApiFetching(
  state: EmailStateType,
  action: ReturnType<typeof va.kyc.email.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: EmailStateType,
  action: ReturnType<typeof va.kyc.email.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: EmailStateType,
  action: ReturnType<typeof va.kyc.email.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetEmail(
  state: EmailStateType,
  action: ReturnType<typeof va.kyc.email.setEmail>,
) {
  state.data = action.payload.email;
}

export function onClearEmail(state: EmailStateType) {
  state.data = "";
  state.fetching = false;
  state.error = {
    message: "",
    status: 0,
  };
}

import { Box, CircularProgress } from "@mui/material";
import StitchLogo from "@ob/assets/svgs/stitch-logo_icon_v2.svg";
import StitchLogoWhite from "@ob/assets/svgs/stitch-logo_icon_v2_white.svg";

type Props = {
  size?: "small" | "medium" | "large";
  position?: object;
  color?: "white" | "black";
};
export default function StitchLoading({
  size = "large",
  position = {},
  color = "black",
}: Props) {
  const large = (
    <Box sx={{ position: "relative", height: "80px" }}>
      <CircularProgress size={80} style={{ color }} />
      <Box
        sx={{
          position: "relative",
          top: -66,
          left: 23,
          ...position,
        }}
      >
        <img
          alt="Stitch Logo"
          src={color === "white" ? StitchLogoWhite : StitchLogo}
          style={{ width: "36px" }}
        />
      </Box>
    </Box>
  );
  if (!size) {
    return large;
  }
  const medium = (
    <Box sx={{ position: "relative", height: "50px" }}>
      <CircularProgress size={50} style={{ color }} />
      <Box
        sx={{
          position: "relative",
          top: -45,
          left: 13,
          ...position,
        }}
      >
        <img
          alt="Stitch Logo"
          src={color === "white" ? StitchLogoWhite : StitchLogo}
          style={{ width: "24px" }}
        />
      </Box>
    </Box>
  );
  if (size === "medium") {
    return medium;
  }
  const small = (
    <Box sx={{ position: "relative", height: "27px" }}>
      <CircularProgress size={25} style={{ color }} />
      <Box
        sx={{
          position: "relative",
          top: -32.5,
          left: 6.5,
          ...position,
        }}
      >
        <img
          alt="Stitch Logo"
          src={color === "white" ? StitchLogoWhite : StitchLogo}
          style={{ width: "12px" }}
        />
      </Box>
    </Box>
  );
  if (size === "small") {
    return small;
  }
  return large;
}

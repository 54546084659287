import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";

import {
  AddressReqType,
  KyvUserTypes,
  UpdateInviteResType,
} from "@ob/api/types";
import { fetchUpdateInvite } from "@ob/api/vendor/invite";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { selectJWT } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";
import {
  selectCity,
  selectState,
  selectStreet1,
  selectStreet2,
  selectZip,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyc/address";

export default function* addressSaga() {
  yield takeLatest(va.kyc.address.submitAddress, onSubmitAddress);
}

export function* onSubmitAddress() {
  try {
    const street1: string = yield select(selectStreet1);
    const street2: string = yield select(selectStreet2);
    const city: string = yield select(selectCity);
    const state: string = yield select(selectState);
    const zip: string = yield select(selectZip);
    if (!street1 || !city || !state || !zip) return;
    yield all([
      put(va.kyc.address.apiFetching(true)),
      put(
        va.kyc.address.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const jwt: string = yield select(selectJWT);

    const addressReq: AddressReqType = {
      line1: street1,
      line2: street2,
      city,
      state,
      postal_code: zip,
    };
    const res: UpdateInviteResType = yield call(
      fetchUpdateInvite,
      jwt,
      { address: addressReq },
      KyvUserTypes.INDIVIDUAL,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.kyc.address.apiError(res.error)),
          put(va.kyc.address.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.kyc.address.apiError(res.error)),
          put(va.kyc.address.apiFetching(false)),
        ]);
      }
    } else {
      yield delay(400);
      yield all([
        put(va.kyc.address.apiSuccess(true)),
        put(va.kyc.address.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Address. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.kyc.address.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.kyc.address.apiFetching(false)),
    ]);
  }
}

import {
  all,
  call,
  put,
  takeLatest,
  takeLeading,
  select,
  delay,
} from "redux-saga/effects";
import type { SubmitPhoneResType, SubmitPhoneCodeResType } from "@ob/api/types";
import { fetchSubmitPhoneCode, fetchSubmitPhone } from "@ob/api/vendor/login";
import { MANUAL_ERROR_CODE } from "@ob/utils/constants";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  selectPhoneCode,
  selectPhoneNumber,
} from "@ob/layouts/VendorOnboarding/redux/selectors/phone";
import { selectJWT } from "@ob/layouts/VendorOnboarding/redux/selectors/auth";

export default function* phoneSaga() {
  yield all([
    takeLatest(va.phone.submitPhone, onSubmitPhone),
    takeLeading(va.phone.retryPhone, onRetryPhone),
    takeLeading(va.phone.resendPhoneCode, onResendPhoneCode),
    takeLeading(va.phone.submitPhoneCode, onSubmitPhoneCode),
  ]);
}

export function* onSubmitPhone() {
  try {
    const phoneNumber: string = yield select(selectPhoneNumber);
    if (!phoneNumber) return;
    yield all([
      put(va.phone.apiFetching(true)),
      put(
        va.phone.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const jwt: string = yield select(selectJWT);

    const res: SubmitPhoneResType = yield call(
      fetchSubmitPhone,
      jwt,
      phoneNumber,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.phone.apiError(res.error)),
          put(va.phone.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.phone.apiError(res.error)),
          put(va.phone.apiFetching(false)),
        ]);
      }
    } else {
      yield all([
        put(va.phone.apiSuccess(true)),
        put(va.phone.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while updating your Phone. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.phone.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.phone.apiFetching(false)),
      put(va.phone.apiSuccess(false)),
    ]);
  }
}

export function* onSubmitPhoneCode() {
  try {
    yield all([
      put(va.phone.apiFetching(true)),
      put(
        va.phone.apiError({
          message: "",
          status: 0,
        }),
      ),
    ]);
    const phoneCode: string = yield select(selectPhoneCode);
    const jwt: string = yield select(selectJWT);
    const res: SubmitPhoneCodeResType = yield call(
      fetchSubmitPhoneCode,
      jwt,
      phoneCode,
    );
    if ("error" in res) {
      if (res.error.status === 401) {
        yield all([
          put(va.routes.redirect("/session_expired")),
          put(va.phone.apiError(res.error)),
          put(va.phone.apiFetching(false)),
        ]);
      } else if (res.error.status === 400) {
        yield all([
          put(
            va.phone.apiError({
              message: "Code doesn't match. Please try again.",
              status: MANUAL_ERROR_CODE,
            }),
          ),
          put(va.phone.clearPhoneCode()),
          put(va.phone.apiFetching(false)),
        ]);
      } else {
        yield all([
          put(va.phone.apiError(res.error)),
          put(va.phone.apiFetching(false)),
        ]);
      }
    } else {
      yield delay(400);
      yield all([
        put(va.phone.apiSuccess(true)),
        put(va.phone.apiFetching(false)),
        put(va.phone.setOtpValid(true)),
        put(va.auth.setLoggedIn(true)),
        put(va.config.getConfig()),
      ]);
      const jwt: string = yield select(selectJWT);
      yield put(va.auth.getUserData(jwt, { redirect: "/linked_accounts" }));
    }
  } catch (error) {
    const errMsg =
      "An error occured while loading submitting your Phone Code. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        va.phone.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(va.phone.apiFetching(false)),
      put(va.phone.apiSuccess(false)),
    ]);
  }
}

function* onRetryPhone() {
  yield put(va.phone.apiFetching(true));
  yield delay(1000);
  yield call(onSubmitPhone);
  yield put(va.phone.apiFetching(false));
}

function* onResendPhoneCode() {
  yield all([
    put(va.phone.clearPhoneCode()),
    put(va.phone.apiSuccess(false)),
    put(va.phone.apiFetching(true)),
    put(
      va.phone.apiError({
        message: "",
        status: 0,
      }),
    ),
  ]);
  yield put(va.phone.setOtpValid(false));
  yield put(va.auth.setLoggedIn(false));
  yield call(onSubmitPhone);
  yield put(va.phone.apiFetching(false));
  yield delay(5000);
}

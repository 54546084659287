import { createAction } from "@reduxjs/toolkit";

const adminInfoActions = {
  setFirstName: createAction(
    "vendor/kyb/admin/info/setFirstName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setLastName: createAction(
    "vendor/kyb/admin/info/setLastName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  setEmail: createAction("vendor/kyb/admin/info/setEmail", (value: string) => ({
    payload: { value },
  })),
  setSSN: createAction("vendor/kyb/admin/info/setSSN", (value: string) => ({
    payload: { value },
  })),
  ssnApiSuccess: createAction(
    "vendor/kyb/admin/info/ssnApiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setDOB: createAction("vendor/kyb/admin/info/setDOB", (value: string) => ({
    payload: { value },
  })),
  setTitle: createAction("vendor/kyb/admin/info/setTitle", (value: string) => ({
    payload: { value },
  })),
};
export default adminInfoActions;

import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";
import { AddressStateType } from "@ob/layouts/VendorOnboarding/types/address";

export const selectStreet1 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.address.street1,
);
export const selectStreet2 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.address.street2,
);
export const selectCity = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.address.city,
);
export const selectState = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.address.state,
);
export const selectZip = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.kyb.admin.address.zip,
);
export const selectAdminAddress = createSelector(
  selectStreet1,
  selectStreet2,
  selectCity,
  selectState,
  selectZip,
  (street1, street2, city, state, zip): AddressStateType => ({
    street1,
    street2,
    city,
    state,
    zip,
  }),
);

import { Link } from "react-router-dom";

// material-ui
import { Button, Stack, Typography } from "@mui/material";

// assets
import error404 from "@ob/assets/images/maintenance/bg-grid.png";
import svg404 from "@ob/assets/svgs/404.svg";
import isDevEnv from "@ob/utils/envCheck";

// ==============================|| ERROR 404 - MAIN ||============================== //

function Page404() {
  const homeUrl = "/";

  return (
    <Stack
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#FFFBE8",
        backgroundImage: `url(${error404})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: 250, sm: 590 },
          height: { xs: 130, sm: 300 },
        }}
      >
        <img
          src={svg404}
          alt="404-not-found"
          style={{
            maxWidth: "300px",
            width: "100%",
            height: "100%",
          }}
        />
      </Stack>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography
          color="primary"
          align="center"
          sx={{ width: { xs: "73%", sm: "61%" } }}
        >
          The page you were looking for cannot be found.
        </Typography>
        <Button component={Link} to={homeUrl} variant="contained">
          Return Home
        </Button>
        {isDevEnv() ? (
          <Button component={Link} to="/debugger" variant="outlined">
            Debugger
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default Page404;

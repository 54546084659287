import { createReducer } from "@reduxjs/toolkit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { BusinessCtrlInfoStateType } from "@ob/layouts/VendorOnboarding/types/kyb/controller/info";

export const initBusinessCtrlInfoStateType = {
  firstName: "",
  lastName: "",
  email: "",
  ssn: "",
  dob: "",
  title: "",
};

const adminInfoReducer = createReducer<BusinessCtrlInfoStateType>(
  initBusinessCtrlInfoStateType,
  (builder) => {
    builder
      .addCase(va.kyb.controller.info.setFirstName, onSetFirstName)
      .addCase(va.kyb.controller.info.setLastName, onSetLastName)
      .addCase(va.kyb.controller.info.setEmail, onSetEmail)
      .addCase(va.kyb.controller.info.setSSN, onSetSSN)
      .addCase(va.kyb.controller.info.setDOB, onSetDOB)
      .addCase(va.kyb.controller.info.setTitle, onSetTitle);
  },
);
export default adminInfoReducer;

export function onSetFirstName(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setFirstName>,
) {
  state.firstName = action.payload.value;
}

export function onSetLastName(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setLastName>,
) {
  state.lastName = action.payload.value;
}

export function onSetEmail(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setEmail>,
) {
  state.email = action.payload.value;
}

export function onSetSSN(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setSSN>,
) {
  state.ssn = action.payload.value;
}

export function onSetDOB(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setDOB>,
) {
  state.dob = action.payload.value;
}

export function onSetTitle(
  state: BusinessCtrlInfoStateType,
  action: ReturnType<typeof va.kyb.controller.info.setTitle>,
) {
  state.title = action.payload.value;
}

import safeFetch from "@ob/api/utils/safeFetch";
import { endpoints } from "@ob/config/envSetup";
import type {
  SubmitPhoneResType,
  SubmitPhoneCodeResType,
  GetOTPStatusResType,
  VendorReloginLinkResType,
} from "@ob/api/types";

export const fetchSubmitPhone = async (
  jwt: string,
  phone: string,
): Promise<SubmitPhoneResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/login?auth=${jwt}`,
    {
      method: "POST",
      body: JSON.stringify({
        phone,
      }),
    },
  );
  if (typeof response === "string") {
    return { data: response };
  }
  return response;
};

export const fetchSubmitPhoneCode = async (
  jwt: string,
  phoneCode: string,
): Promise<SubmitPhoneCodeResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/login?auth=${jwt}`,
    {
      method: "PUT",
      body: JSON.stringify({
        otp: phoneCode,
      }),
    },
  );
  return response;
};

export const fetchGetOTPStatus = async (
  jwt: string,
): Promise<GetOTPStatusResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/onboarding/invite/status?auth=${jwt}`,
    { method: "GET" },
  );
  return response;
};

export const fetchSendVendorReloginLink = async (
  oldJwt: string,
): Promise<VendorReloginLinkResType> => {
  const url = `${endpoints.stitchBackend}/auth/login/onboarding`;
  const response = await safeFetch(url, {
    method: "POST",
    credentials: "omit",
    body: JSON.stringify({
      jwt: oldJwt,
    }),
  });
  return response;
};

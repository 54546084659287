import { KyvUserTypes } from "@ob/api/types";
import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createAction } from "@reduxjs/toolkit";

const vendorSubmitActions = {
  apiFetching: createAction(
    "vendor/submit/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction("vendor/submit/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("vendor/submit/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  submitInvite: createAction(
    "vendor/submit/submitInvite",
    (userType: KyvUserTypes) => ({
      payload: { userType },
    }),
  ),
  setInviteStatus: createAction(
    "vendor/submit/setInviteStatus",
    (status: string) => ({
      payload: {
        status,
      },
    }),
  ),
  setInviteData: createAction(
    "vendor/submit/setInviteData",
    (payload: {
      clientId: string;
      sessionToken: string;
      userIntentId: string;
    }) => ({
      payload,
    }),
  ),
  clearInviteData: createAction("vendor/submit/clearInviteData"),
  setManualUpdateInvite: createAction(
    "vendor/submit/setManualUpdateInvite",
    (payload: boolean) => ({
      payload,
    }),
  ),
};
export default vendorSubmitActions;

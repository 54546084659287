import { ErrorPayload } from "@ob/api/utils/safeFetch";
import { createSelector } from "@reduxjs/toolkit";
import { VendorStateType } from "@ob/layouts/VendorOnboarding/types";

export const selectStreet1 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.address.data.street1,
);
export const selectStreet2 = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.address.data.street2,
);
export const selectCity = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.address.data.city,
);
export const selectState = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.address.data.state,
);
export const selectZip = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): string => vendor.address.data.zip,
);

export const selectAddressFetching = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.address.fetching,
);

export const selectAddressError = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): ErrorPayload => vendor.address.error,
);

export const selectAddressSuccess = createSelector(
  (state: { vendor: VendorStateType }) => state.vendor,
  (vendor): boolean => vendor.address.success,
);

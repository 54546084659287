import { useState, useEffect } from "react";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
  Modal,
  Backdrop,
  Fade,
  useTheme,
} from "@mui/material";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { useAppDispatch } from "@ob/redux/store";

type Props = {
  hasBanks: boolean;
  hasCards: boolean;
  show: boolean;
  fetching: boolean;
  success: boolean;
  onClose: () => void;
  onDelete: (type: string) => void;
};
export default function ConfirmDeleteCardModal(props: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [shouldDisable, setShouldDisable] = useState(false);

  useEffect(
    () => () => {
      dispatch(va.payDest.apiFetching(false));
    },
    [],
  );

  let title = "Unlink";
  if (!props.hasBanks && props.hasCards) {
    title = "Unlink Card";
  }
  if (!props.hasCards && props.hasBanks) {
    title = "Unlink Bank";
  }

  const handleDelete = (type: string) => {
    if (!shouldDisable) {
      setShouldDisable(true);
      props.onDelete(type);
    }
  };

  if (props.success && shouldDisable) {
    setShouldDisable(false);
    setTimeout(() => {
      navigate("/delete_success", {
        state: { caller: "delete" },
        replace: false,
      });
    }, 1500);
  }

  if (!props.show) return null;

  return (
    <Modal
      open={props.show}
      onClose={props.onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            py: "20px",
            px: "40px",
            width: "90vw",
            maxWidth: "500px",
            minHeight: "300px",
            borderRadius: "4px",
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: "CircularBold" }}>
            {props.success ? "Success!" : title}
          </Typography>
          <Typography variant="body1">
            Are you sure? You may not receive payouts if you do not have a card
            or bank on file.
          </Typography>
          <Stack gap={2}>
            <StitchAsyncButton
              buttonText="Yes, delete"
              buttonWidth="145px"
              variant="contained"
              color="error"
              logoColor="white"
              onClick={() =>
                handleDelete(title === "Unlink Bank" ? "bank" : "card")
              }
              loading={props.fetching}
              success={props.success}
              loadingSize="small"
              loadingPosition={{ top: -30, left: 0 }}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => props.onClose()}
              sx={{ width: "145px", color: theme.palette.primary.main }}
            >
              No, {"don't"} delete
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}

import { CreditCardDbType } from "@ob/redux/types/dbTypes";

export function isCardExpired(dateStr: string): boolean {
  if (!/^(0[1-9]|1[0-2]|[1-9])\/([0-9]{2})$/.test(dateStr)) {
    throw new Error("Invalid card expiration date, must be form MM/YY");
  }
  const [month, year] = dateStr.split("/");
  const nextMonth = new Date(parseInt(`20${year}`, 10), parseInt(month, 10), 1);
  nextMonth.setDate(nextMonth.getDate() - 1);
  const now = new Date();
  return now > nextMonth;
}

export function isCardIneligible(card: CreditCardDbType): boolean {
  return !card.active || card.disabled || !card.isPushEnabled;
}

import { AddressDbType, DirtyAddressDbType } from "@ob/redux/types/dbTypes";

export default function transformBeToFeAddr(
  addr: DirtyAddressDbType,
): AddressDbType {
  return {
    street1: addr.line1,
    street2: addr.line2,
    zip: addr.postalCode,
    city: addr.city,
    state: addr.state,
  };
}

import { AddressStateType } from "@ob/layouts/VendorOnboarding/types/address";

export type SingleBeneficialOwnerInfoStateType = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  dob: string;
  title: string;
};

export type SingleBeneficialOwnerStateType = {
  id?: string;
  info: SingleBeneficialOwnerInfoStateType;
  address: AddressStateType;
};

export type BeneficialOwnersStateType = {
  activeIndex: number;
  allOwners: SingleBeneficialOwnerStateType[];
};

export function isKeyInOwnerInfoType(
  key: string,
): key is keyof SingleBeneficialOwnerInfoStateType {
  const infoKeys = ["firstName", "lastName", "email", "ssn", "dob", "title"];
  return infoKeys.includes(key);
}

export function isKeyInOwnerAddressType(
  key: string,
): key is keyof AddressStateType {
  const addressKeys = ["street1", "street2", "city", "state", "zip"];
  return addressKeys.includes(key);
}

import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import ReactDOM from "react-dom/client";
import store from "@ob/redux/store";
import App from "@ob/App/App";
import ScrollTopTop from "@ob/components/providers/ScrollToTop";
import ThemeCustomization from "@ob/mantis/themes/index";
import { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeCustomization>
      <SkeletonTheme
        baseColor="#black"
        highlightColor="#D3D3D3"
        borderRadius="0.5rem"
        duration={4}
      >
        <ReduxProvider store={store}>
          <ScrollTopTop>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ScrollTopTop>
        </ReduxProvider>
      </SkeletonTheme>
    </ThemeCustomization>
  </React.StrictMode>,
);

import { createAsyncSlice } from "@ob/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { SubmitStateType } from "@ob/layouts/VendorOnboarding/types/submit";
import va from "@ob/layouts/VendorOnboarding/redux/actions";

export const initSubmitInviteState = createAsyncSlice({
  status: "",
  manualUpdateInvite: false,
});

const submitInviteReducer = createReducer<SubmitStateType>(
  initSubmitInviteState,
  (builder) => {
    builder
      .addCase(va.submit.apiFetching, onApiFetching)
      .addCase(va.submit.apiError, onApiError)
      .addCase(va.submit.apiSuccess, onApiSuccess)
      .addCase(va.submit.setManualUpdateInvite, onManualUpdateInvite)
      .addCase(va.submit.setInviteStatus, onSetInviteStatus);
  },
);
export default submitInviteReducer;

export function onApiFetching(
  state: SubmitStateType,
  action: ReturnType<typeof va.submit.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: SubmitStateType,
  action: ReturnType<typeof va.submit.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: SubmitStateType,
  action: ReturnType<typeof va.submit.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onManualUpdateInvite(
  state: SubmitStateType,
  action: ReturnType<typeof va.submit.setManualUpdateInvite>,
) {
  state.data.manualUpdateInvite = action.payload;
}

export function onSetInviteStatus(
  state: SubmitStateType,
  action: ReturnType<typeof va.submit.setInviteStatus>,
) {
  state.data.status = action.payload.status;
}

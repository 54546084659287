import { CreditCardOutlined } from "@ant-design/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import {
  isCardExpired,
  isCardIneligible,
} from "@ob/layouts/VendorOnboarding/utils/cards";
import { CreditCardDbType } from "@ob/redux/types/dbTypes";

type Props = {
  card: CreditCardDbType;
};
export default function CardDetails(props: Props) {
  const theme = useTheme();
  const { card } = props;
  const cardExpired = isCardExpired(card.expiration);
  const cardIneligible = isCardIneligible(card);
  return (
    <Stack
      key={card.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        pt: 2,
        minHeight: 5,
        mt: 4,
      }}
    >
      <Stack direction="row" alignItems="center">
        <CreditCardOutlined style={{ fontSize: "24px" }} />
        <Stack sx={{ ml: 2 }}>
          <Typography variant="body1">
            {card.cardCompany} {card.mask}
          </Typography>
          <Typography variant="body1" color="secondary">
            Exp: {card.expiration}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <div
            style={{
              display: !card.active ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            inactive
          </div>
        </Stack>
        <Stack>
          <div
            style={{
              display: cardIneligible ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            ineligible
          </div>
        </Stack>
        <Stack>
          <div
            style={{
              display: cardExpired && !cardIneligible ? "block" : "none",
              backgroundColor: theme.palette.error.main,
              color: "white",
              padding: "5px 2px",
              borderRadius: "5px",
              width: "75px",
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "center",
              fontFamily: "CircularBold",
              textTransform: "capitalize",
            }}
          >
            expired
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { RightOutlined } from "@ant-design/icons";
import { ButtonBase, Stack, Typography } from "@mui/material";

type Props = {
  firstName: string;
  lastName: string;
  title?: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  onEditRow: () => void;
};

export default function BusinessPersonRow({
  title = "",
  street2 = "",
  ...props
}: Props) {
  const nameAndTitle = title
    ? `${props.firstName} ${props.lastName}, ${title}`
    : `${props.firstName} ${props.lastName}`;

  const singleLineAddress = street2
    ? `${props.street1}, ${street2}, ${props.city}, ${props.state} ${props.zip}`
    : `${props.street1}, ${props.city}, ${props.state} ${props.zip}`;

  return (
    <ButtonBase
      sx={{
        alignItems: "flex-start",
        width: "100%",
        pt: "15px",
        pb: "15px",
      }}
      onClick={props.onEditRow}
    >
      <Stack width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack width="100%">
            <Typography align="left">{nameAndTitle}</Typography>
            <Typography
              align="left"
              color="gray"
              sx={{ whiteSpace: "initial", width: "80%" }}
            >
              {singleLineAddress}
            </Typography>
          </Stack>
          <RightOutlined style={{ fontSize: "12px" }} />
        </Stack>
      </Stack>
    </ButtonBase>
  );
}
